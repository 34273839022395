import React from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';

import { hintShape, inputShape, metaShape, optionsShape } from '../../../propTypes/field';

import FieldWrapper from './FieldWrapper';
import FieldSelectboxOption from './FieldSelectboxOption';
import SvgLoader from '../media/MediaSvgLoader';

function renderOption(option, index) {
  return (
    <FieldSelectboxOption
      label={option.label}
      value={option.value}
      key={index}
    />
  );
}

const componentName = 'FieldSelectbox';
function FieldSelectbox(props) {
  const {
    label,
    input,
    options,
    sublabel,
  } = props;

  return (
    <FieldWrapper
      componentName={componentName}
      modifiers={[!label && 'withoutLabel']}
      {...props}
    >
      {wrapperProvider => (
        <div>
          {label &&
            <div
              className={suitcss({
                componentName,
                descendantName: 'label',
              })}
            >
              {label}
              {!wrapperProvider.isRequired && wrapperProvider.getIsOptionalElement()}
            </div>
          }
          <div
            className={suitcss({
              componentName,
              descendantName: 'inner',
            })}
          >
            <select
              className={suitcss({
                componentName,
                descendantName: 'input',
              })}
              {...input}
            >
              {sublabel &&
                <FieldSelectboxOption
                  label={sublabel}
                  value=""
                  disabled
                />
              }
              {options && options.map((entry, index) => {
                if (entry.options) { // entry with options means we are in an options group
                  return (
                    <optgroup key={index} label={entry.label}>
                      {entry.options.map(renderOption)}
                    </optgroup>
                  );
                }

                // render simple options
                return renderOption(entry, index);
              })
              }
            </select>
            <div
              className={suitcss({
                componentName,
                descendantName: 'border',
              })}
            />
            <div
              className={suitcss({
                componentName,
                descendantName: 'arrow',
              })}
            >
              <SvgLoader path="/icons/content-arrow.svg" />
            </div>
          </div>
        </div>
      )}
    </FieldWrapper>
  );
}

FieldSelectbox.propTypes = {
  sublabel: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        options: optionsShape,
      }),
    ),
    optionsShape,
  ]),
  type: PropTypes.string,
  label: PropTypes.string,
  hint: hintShape,
  input: inputShape,
  meta: metaShape,
  raw: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  utitilies: PropTypes.array,
  theme: PropTypes.oneOf(['default', 'compact']),
};

FieldSelectbox.defaultProps = {
  meta: {},
  input: {},
  options: [],
  theme: 'default',
};

export default FieldSelectbox;
