import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { bindQueryParams } from '../../../helpers/url';
import {
  DIALOG_ID_SHIPPING_FREE_INFO,
  DIALOG_ID_SHIPPING_INFO,
  PAYMENT_FEE_SINGLE,
  QUERY_SELECTED_HARDWARE,
  QUERY_SELECTED_TARIFF,
} from '../../../helpers/constants';
import connectUI from '../../basics/ui/UIConnector';
import { list as hardwareListShape, shape as hardwareShape } from '../../../propTypes/hardware';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import MediaImage from '../../basics/media/MediaImage';
import HardwareSelector from './HardwareSelector';
import Callout from '../../../containers/callout/Callout';
import imageSizes from '../../../config/imageSizes';

import HardwarePaymentUnit from '../../../containers/hardware/HardwarePaymentUnit';
import { DELIVERY_STATE_AVAILABILITY_IN_TIME, ORDER_STATE_UNAVAILABLE } from '../../../propTypes/common';
import Tag from '../../basics/text/Tag';
import { showTemplateDialog } from '../../../actions/page/dialog';

class HardwareItemCompact extends PureComponent {

  getSelectedHardware() {
    const { hardwareList, selectedHardware } = this.props;
    return selectedHardware || hardwareList[0];
  }

  getUrlDetails() {
    const { selectedTariffId } = this.props;
    const selectedHardware = this.getSelectedHardware();
    return bindQueryParams(selectedHardware.urlDetails, {
      [QUERY_SELECTED_HARDWARE]: selectedHardware.eid,
      [QUERY_SELECTED_TARIFF]: selectedTariffId,
    });
  }

  renderSelector() {
    const { hardwareList, onChange } = this.props;
    const selectedHardware = this.getSelectedHardware();
    return (
      <div
        className={suitcss({
          descendantName: 'selector',
        }, this)}
      >
        <HardwareSelector
          theme="compact"
          hardwareList={hardwareList.filter(item => item.orderState !== ORDER_STATE_UNAVAILABLE)}
          selectedHardware={selectedHardware}
          onChange={onChange}
        />
      </div>
    );
  }

  renderTitle(utilities) {
    const { name, isNew, ui } = this.props;
    const selectedHardware = this.getSelectedHardware();
    return (
      <div
        className={suitcss({
          descendantName: 'title',
          utilities,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'headline',
          }, this)}
        >
          <Headline size="s" embedded>
            {name}
            {isNew &&
              <React.Fragment>
                &nbsp;
                <Tag theme="tertiary" label={ui.guiWordNew} />
              </React.Fragment>
            }
            &nbsp;
            <Callout
              targets={[selectedHardware.groupId]}
              theme="sticker"
            />
          </Headline>
        </div>
        <div
          className={suitcss({
            utilities: ['hidden', 'smBlock'],
          }, this)}
        >
          <Link
            to={this.getUrlDetails()}
          >
            {ui.guiWordDetails}
          </Link>
        </div>
      </div>
    );
  }

  renderCopy(utilities) {
    const { ui } = this.props;
    return (
      <div
        className={suitcss({
          descendantName: 'copy',
          utilities,
        }, this)}
      >
        <Copy size="tertiary" embedded >
          {ui.guiFeeExtraSingular}
        </Copy>
      </div>
    );
  }

  renderVisuals(utilities) {
    const selectedHardware = this.getSelectedHardware();

    const hardwareImage = {
      ...selectedHardware.image,
      sizes: imageSizes.hardwareItemCompact,
    };

    return (
      <div
        className={suitcss({
          descendantName: 'visuals',
          utilities,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'image',
          }, this)}
        >
          <Link
            withoutStyle
            to={this.getUrlDetails()}
          >
            <MediaImage {...hardwareImage} isLazy />
          </Link>
        </div>
      </div>
    );
  }

  renderPrices(utilities) {
    const { ui, selectedTariffId } = this.props;
    const selectedHardware = this.getSelectedHardware();

    return (
      <div
        className={suitcss({
          descendantName: 'prices',
          utilities,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'price',
          }, this)}
        >
          {this.renderCopy(['hidden', 'lBlock'])}
          <div
            className={suitcss({
              descendantName: 'unit',
              utilities: ['hidden', 'smBlock'],
            }, this)}
          >
            <HardwarePaymentUnit
              hardware={selectedHardware}
              tariffId={selectedTariffId}
              paymentType={PAYMENT_FEE_SINGLE}
              postfix={ui.guiWordSingularAbbr}
              size="inherit"
            />
          </div>
          <div
            className={suitcss({
              descendantName: 'unit',
              utilities: ['hidden', 'lBlock'],
            }, this)}
          >
            <HardwarePaymentUnit
              hardware={selectedHardware}
              tariffId={selectedTariffId}
              paymentType={PAYMENT_FEE_SINGLE}
              postfix={ui.guiWordSingularAbbr}
              size="s"
            />
          </div>
          {this.renderCopy(['hidden', 'mBlock'])}
          {this.renderDeliveryHint(['hidden', 'lBlock'])}
        </div>
      </div>
    );
  }

  renderDeliveryHint(utilities) {
    const { ui, dispatch, shippingFee } = this.props;
    const selectedHardware = this.getSelectedHardware();

    let deliveryState = ui[`guiDeliveryState${selectedHardware.deliveryState}`];
    if (selectedHardware.deliveryState === DELIVERY_STATE_AVAILABILITY_IN_TIME) {
      deliveryState = deliveryState.replace('{DELIVERY_TIME}', selectedHardware.deliveryTime);
    }

    return (
      <div
        className={suitcss({
          descendantName: 'hardwareDeliveryHint',
          utilities,
        }, this)}
      >
        <Copy
          utilities={['marginTopS', 'textSizeSecondary', 'colorGray100']}
          embedded
          raw
        >
          {deliveryState}
        </Copy>
        <div
          className={suitcss({
            descendantName: 'shippingFeeHint',
            utilities: ['marginBottomXS', 'colorGray100'],
          }, this)}
        >
          <Copy
            utilities={[
              'textSizeSecondary',
              'smMarginBottomS',
              'inline',
              'marginRightXXS',
            ]}
            embedded
            raw
          >
            {ui.guiShippingCosts}
          </Copy>
          <Link
            className={suitcss(
              { descendantName: 'infoIcon' },
              this,
            )}
            element="button"
            onClick={() => dispatch(showTemplateDialog(
              shippingFee.unit > 0 ? DIALOG_ID_SHIPPING_INFO : DIALOG_ID_SHIPPING_FREE_INFO,
            ))}
            withoutArrow
            withoutStyle
            icon="/icons/content-info.svg"
            size="small"
          />
        </div>
      </div>
    );
  }


  render() {
    const { ui } = this.props;
    const selectedHardware = this.getSelectedHardware();
    return (
      <div
        className={suitcss({}, this)}
        data-tracking="hardware"
        data-hardware-id={selectedHardware.iid}
      >
        {this.renderTitle(['hidden', 'lBlock'])}
        {this.renderVisuals(['hidden', 'mlBlock'])}
        <div
          className={suitcss({
            descendantName: 'inner',
            utilities: ['hidden', 'lBlock'],
          }, this)}
        >
          {this.renderSelector()}
          <div
            className={suitcss({
              descendantName: 'link',
            }, this)}
          >
            <Link to={this.getUrlDetails()}>
              {ui.guiWordDetails}
            </Link>
          </div>
          {this.renderPrices()}
        </div>
        <div
          className={suitcss({
            descendantName: 'inner',
            utilities: ['hidden', 'smBlock'],
          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'content',
            }, this)}
          >
            <div
              className={suitcss({
                descendantName: 'hardwareSelection',
              }, this)}
            >
              {this.renderVisuals(['hidden', 'sBlock'])}
              {this.renderTitle(['hidden', 'smBlock'])}
              {this.renderPrices(['hidden', 'smBlock'])}
            </div>
            {this.renderDeliveryHint()}
          </div>
          <div
            className={suitcss({
              descendantName: 'subcontent',
            }, this)}
          >
            {this.renderSelector()}
          </div>
        </div>
        <Callout
          theme="badge"
          targets={[selectedHardware, selectedHardware.groupId]}
        />
      </div>
    );
  }
}

HardwareItemCompact.propTypes = {
  name: PropTypes.string.isRequired,
  selectedHardware: hardwareShape,
  selectedTariffId: PropTypes.string,
  hardwareList: hardwareListShape.isRequired,
  onChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  ui: PropTypes.shape({
    guiWordDetails: PropTypes.string.isRequired,
    guiFeeExtraSingular: PropTypes.string.isRequired,
    guiWordSingularAbbr: PropTypes.string,
    guiDeliveryState1: PropTypes.string.isRequired,
    guiDeliveryState2: PropTypes.string.isRequired,
    guiDeliveryState3: PropTypes.string.isRequired,
    guiShippingCosts: PropTypes.string,
  }),
  isNew: PropTypes.bool,
  shippingFee: PropTypes.object,
};

HardwareItemCompact.defaultProps = {};

export default connectUI()(HardwareItemCompact);
