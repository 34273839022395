/* global document */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from '../media/MediaSvgLoader';

class CustomDropdown extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isDropdownOpen: false,
      selectedOption: props.selectedOption || '',
    };
    this.componentRef = React.createRef();
    this.dataListRef = React.createRef();
    this.optionListRef = React.createRef();
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClose, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClose, false);
  }

  onReset() {
    const options = this.dataListRef.current ? this.dataListRef.current.children : null;

    this.setState({ isDropdownOpen: true, selectedOption: '' });

    if (options && !!options.length) {
      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('u-hidden', 'is-active');
      }
    }
  }

  onFocus() {
    this.setState({ isDropdownOpen: true });

    if (this.props.onFocus) this.props.onFocus();
  }

  onClose(e) {
    if (this.dataListRef &&
      this.componentRef &&
      this.componentRef.current &&
      !this.componentRef.current.contains(e.target)) {
      this.setState({ isDropdownOpen: false });
    }
  }

  onInput(e) {
    const options = this.dataListRef.current ? this.dataListRef.current.children : null;
    if (options && !!options.length) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].innerText.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1) {
          options[i].classList.remove('u-hidden');
        } else {
          options[i].classList.add('u-hidden');
        }
      }
    }
    if (this.props.onInput) {
      this.props.onInput();
    }
  }

  onInputChange(e) {
    const options = this.dataListRef.current ? this.dataListRef.current.children : null;
    const hasNoOptions = Array.from(options).every(element => element.classList.contains('u-hidden'));
    this.setState({ isDropdownOpen: !hasNoOptions });
    this.setState({ selectedOption: e.target.value });
    this.getActiveOption(e.target.value, options);
  }

  onOptionChange(value, isDataList = false) {
    const options = isDataList ?
      this.dataListRef && this.dataListRef.current ?
        this.dataListRef.current.children :
        null :
      this.optionListRef && this.optionListRef.current ?
        this.optionListRef.current.children :
        null;
    this.getActiveOption(value, options);
    this.setState({
      selectedOption: value,
      isDropdownOpen: false,
    });
    if (this.props.onOptionChange) {
      this.props.onOptionChange(value);
    }
  }

  onToggleOptionList() {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  }

  getActiveOption(value, options) {
    if (options && !!options.length) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].innerText.toUpperCase() === value.toUpperCase()) {
          options[i].classList.add('is-active');
        } else {
          options[i].classList.remove('is-active');
        }
      }
    }
  }

  renderOptionList() {
    const { selectedOption, isDropdownOpen } = this.state;
    const { options, label, placeholder } = this.props;
    if (!options || !options.length) return null;
    return (
      <Fragment>
        <div
          className={suitcss({ descendantName: 'input' }, this)}
          onClick={() => this.onToggleOptionList(this)}
        >
          {label && !selectedOption && (
            <div className={suitcss({ descendantName: 'label' }, this)}>{label}</div>
          )}
          {placeholder &&
            <span>{selectedOption || placeholder}</span>
          }
          <div className={suitcss({ descendantName: 'arrow' }, this)}>
            <SvgLoader path="/icons/content-arrow.svg" />
          </div>
        </div>
        <div
          ref={this.optionListRef}
          className={suitcss(
            { descendantName: 'options', states: [!isDropdownOpen && 'closed'] },
            this,
          )}
        >
          {options.map((option, index) => (
            <div
              className={suitcss({
                  descendantName: 'option',
                  className: option.classNames,
                },
                this,
              )}
              key={option.value + index}
              onClick={() => this.onOptionChange(option.value, false, this)}
            >
              {option.value}
            </div>
          ))}
        </div>
      </Fragment>
    );
  }

  renderDataList() {
    const { selectedOption, isDropdownOpen } = this.state;
    const { options, label, placeholder } = this.props;
    if (!options || !options.length) return null;
    return (
      <Fragment>
        {label && !selectedOption && (
          <label className={suitcss({ descendantName: 'label' }, this)} htmlFor="dataList">{label}</label>
        )}
        <input
          className={suitcss({ descendantName: 'input' }, this)}
          list=""
          name="dataList"
          autoComplete="off"
          placeholder={placeholder || null}
          value={selectedOption}
          onInput={(e) => this.onInput(e, this)}
          onChange={(e) => this.onInputChange(e, this)}
          onFocus={() => this.onFocus(this)}
        />
        <datalist
          ref={this.dataListRef}
          className={suitcss({ descendantName: 'options', states: [!isDropdownOpen && 'closed'] }, this)}
        >
          {options.map((option, index) => (
            <div
              className={suitcss(
                {
                  descendantName: 'option',
                  className: option.classNames,
                },
                this,
              )}
              key={option.value + index}
              onClick={() => this.onOptionChange(option.value, true, this)}
            >
              {option.value}
            </div>
          ))}
        </datalist>
        <div className={suitcss({ descendantName: 'arrow' }, this)}>
          <SvgLoader path="/icons/content-arrow.svg" />
        </div>
      </Fragment>
    );
  }

  render() {
    const { selectedOption, isDropdownOpen } = this.state;
    const { asDataList } = this.props;

    return (
      <div
        ref={this.componentRef}
        className={suitcss(
          {
            states: [selectedOption && 'selected', isDropdownOpen && 'open'],
          },
          this,
        )}
      >
        {asDataList ? (
          this.renderDataList(this)
        ) : (
          this.renderOptionList(this)
        )}
      </div>
    );
  }
}

CustomDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    classNames: PropTypes.array,
  })),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.string,
  onOptionChange: PropTypes.func,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  asDataList: PropTypes.bool,
};

export default CustomDropdown;
