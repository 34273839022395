import { LOCATION_CHANGE } from 'react-router-redux';
import {
  REPLACE_CART,
  CHANGE_TARIFF_OPTIONS,
  CLEAR_CART,
  replaceCart,
  HIDE_MNP,
  hideMnp,
  REMOVE_CART_ITEM, UPDATE_CART_SIM_TYPE, CLEAR_CART_SIM_TYPE,
  clearCartSimType,
  updateCartSimType,
} from '../actions/order/cart';
import { CONFIRM_ORDER } from '../actions/order/completed';
import {
  REGISTER_VOID,
  REGISTER_AFFILIATE_ID,
  registerPromoCodes,
  REGISTER_CID,
  REGISTER_ORDER_BY_AGENT,
  registerVoId,
  registerCId,
  orderByAgent,
  registerCampaignToken,
  REMOVE_CAMPAIGN_TOKEN,
  REMOVE_PROMOTION_CODES,
  removeCampaignToken,
  registerGrantedPromotions,
  REGISTER_DENIED_PROMOTIONS,
  registerDeniedPromotions,
} from '../actions/user/marketing';
import { RECEIVED_CREDENTIALS } from '../actions/user/login';
import { LOGOUT } from '../actions/user/logout';
import { DOCUMENT_READ } from '../actions/user/inbox';
import { UPDATE_DIALOG_STATE } from '../actions/page/dialog';
import { DAY, HOUR, MONTH } from '../helpers/date';
import { isGrantedCampaignPromotion, isGrantedPromotion } from '../helpers/promotions';
import MyPromotionsRequest from '../model/requests/MyPromotionsRequest';
import PromotionsRequest from '../model/requests/PromotionsRequest';
import ReauthRequest from '../model/requests/ReauthRequest';
import { REQUEST_RECEIVED_RESPONSE } from '../actions/request/basic';
import { isTariffEntity } from '../helpers/entity';
import { getAvailableTariffs } from '../selectors/misc';
import {
  updateShoppingBag,
  UPDATE_SHOPPINGBAG,
  SYNC_STORAGE,
  CLEAR_SHOPPINGBAG,
  CLEAR_CAMPAIGN_TOKEN,
  UPDATE_CAMPAIGN_TOKEN,
  clearCampaignToken,
} from '../actions/global/storage';
import {
  STORAGE_KEY_AFFILIATE,
  STORAGE_KEY_CAMPAIGN_TOKEN,
  STORAGE_KEY_CONSENT_INQUIRY_DIALOG,
  STORAGE_KEY_CREDENTIALS,
  STORAGE_KEY_DOCUMENT_STATES,
  STORAGE_KEY_SHOPPINGBAG,
  STORAGE_KEY_ESIM,
  STORAGE_KEY_CART_SIM_TYPE,
} from '../helpers/constants';
import {
  getItem,
  removeItem,
  setItem,
  STORAGE_TYPE_COOKIE,
  STORAGE_TYPE_LOCAL_STORAGE,
  STORAGE_TYPE_SESSION_STORAGE,
} from '../helpers/storage';

const storageMiddleware = ({ getState, dispatch }) => next => (action) => {
  switch (action.type) {
    case REPLACE_CART: {
      const { payload: cart } = action;
      const { site, user } = getState();
      if (getAvailableTariffs(site, user, cart.filter(isTariffEntity)).length) {
        dispatch(updateShoppingBag({ entities: cart }));
        return next(action);
      }
      return; // do not propagate event
    }
    case CHANGE_TARIFF_OPTIONS:
    case REMOVE_CART_ITEM: {
      const dispatchResult = next(action);
      dispatch(updateShoppingBag({ entities: getState().cart }));
      return dispatchResult;
    }
    case CLEAR_CART: {
      const dispatchResult = next(action);
      dispatch(updateCartSimType(null));
      dispatch(clearCartSimType());
      const { site } = getState();
      if (!site.contractRenewal.isInProgress) {
        dispatch(registerGrantedPromotions({ promotions: [], promoCodes: [] }));
        dispatch(updateShoppingBag({ entities: null, promoCodes: [] }));
        return dispatchResult;
      }
      // according to DBNHAMOP-4465 vvl should be able to accept promoCodes
      // without removing from storage when stop renewcontract
      dispatch(updateShoppingBag({ entities: null }));
      dispatch(updateCartSimType(null));
      dispatch(clearCartSimType());
      dispatch(registerGrantedPromotions({ promotions: [], promoCodes: [] }));
      dispatch(updateShoppingBag({ entities: null, promoCodes: [] }));
      return dispatchResult;
    }
    case CONFIRM_ORDER: {
      const dispatchResult = next(action);
      dispatch(updateShoppingBag({ entities: null }));
      dispatch(clearCampaignToken());
      dispatch(clearCartSimType());
      return dispatchResult;
    }
    case LOGOUT: {
      const dispatchResult = next(action);
      removeItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CREDENTIALS);
      removeItem(STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_CREDENTIALS);
      removeItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CREDENTIALS);
      removeItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_DOCUMENT_STATES);
      removeItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CONSENT_INQUIRY_DIALOG);
      removeItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_ESIM);
      return dispatchResult;
    }
    case RECEIVED_CREDENTIALS: {
      const dispatchResult = next(action);
      const { isPersist } = action.payload;
      const storageType = isPersist
        ? STORAGE_TYPE_COOKIE
        : STORAGE_TYPE_SESSION_STORAGE;
      const { token, refreshToken, ...modCredentials } = getState().user.credentials;
      setItem(storageType, STORAGE_KEY_CREDENTIALS, modCredentials, {
        expires: new Date(Date.now() + (DAY * 42)),
        path: '/',
      });
      return dispatchResult;
    }
    case REQUEST_RECEIVED_RESPONSE: {
      const dispatchResult = next(action);
      const { meta, payload } = action;
      const { body } = payload.response;
      const { request } = meta;
      if (request instanceof ReauthRequest) {
        let credentials =
          getItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CREDENTIALS);
        let storageType = STORAGE_TYPE_COOKIE;

        if (!credentials) { // expect it to be in session storage
          credentials = getItem(STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_CREDENTIALS);
          storageType = STORAGE_TYPE_SESSION_STORAGE;
        }
        setItem(storageType, STORAGE_KEY_CREDENTIALS, {
          ...credentials,
        }, {
          expires: new Date(Date.now() + (DAY * 42)),
          path: '/',
        });
      }

      // according to DBNHAMOP-4465 vvl should be able to accept promoCodes,
      // we added also the MyPromotionsRequest
      if (request instanceof PromotionsRequest && (request.url.includes('campaignToken=') || request.url.includes('promoCode='))) {
        const requestUrlParams = new URLSearchParams(request.url.substring(request.url.indexOf('?')));
        const promoCodeQuery = requestUrlParams.get('promoCode');
        // campaignToken should also be removed from storage, if there is no containing promo
        // e.g. a valid token gets invalid during process, we need to remove it.
        const grantedCampaignPromotions = Object.values(body.data)
          .filter(promo => isGrantedCampaignPromotion(promo))
          .map(promo => promo.eid);
        if (!grantedCampaignPromotions.length) {
          dispatch(removeCampaignToken());
        }
        const grantedPromotions = Object.values(body.data)
          .filter(promo => isGrantedPromotion(promo))
          .map(promo => promo.eid);
        if (grantedPromotions.length) {
          dispatch(registerGrantedPromotions({
            promotions: grantedPromotions,
            promoCodes: [promoCodeQuery],
          }));
        } else {
          dispatch(registerDeniedPromotions([promoCodeQuery]));
        }
      }

      if (request instanceof MyPromotionsRequest && request.url.includes('promoCode=')) {
        const requestUrlParams = new URLSearchParams(request.url.substring(request.url.indexOf('?')));
        const promoCodeQuery = requestUrlParams.get('promoCode');
        const { site } = getState();
        const grantedPromotions = Object.values(body.data)
          .filter(promo => isGrantedPromotion(promo))
          .map(promo => promo.eid);
        if (grantedPromotions.length) {
          dispatch(registerGrantedPromotions({
            promotions: grantedPromotions,
            promoCodes: [promoCodeQuery],
            isRenewContract: site.contractRenewal.isInProgress,
          }));
        } else {
          dispatch(registerDeniedPromotions([promoCodeQuery]));
        }
      }

      return dispatchResult;
    }
    case DOCUMENT_READ: {
      const dispatchResult = next(action);
      const { documents } = getState().user.inbox;
      if (documents) {
        setItem(STORAGE_TYPE_COOKIE, 'documentStates', documents, {
          expires: new Date(Date.now() + MONTH),
          path: '/',
        });
      }
      return dispatchResult;
    }
    case UPDATE_DIALOG_STATE: {
      const dispatchResult = next(action);
      const { dialogStates } = getState().site;
      if (action.payload && action.payload.id) {
        dialogStates[action.payload.id] = action.payload;
        setItem(STORAGE_TYPE_COOKIE, 'dialogStates', dialogStates, {
          expires: new Date(Date.now() + MONTH),
          path: '/',
        });
      }
      return dispatchResult;
    }
    case LOCATION_CHANGE: {
      const dispatchResult = next(action);
      const { lastVisit } = getState().user;
      if (!lastVisit) {
        return dispatchResult;
      }
      setItem(STORAGE_TYPE_COOKIE, 'last_visit', lastVisit, {
        expires: new Date(Date.now() + (MONTH * 6)),
        path: '/',
      });
      removeItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_CONSENT_INQUIRY_DIALOG);
      return dispatchResult;
    }
    case REGISTER_AFFILIATE_ID: {
      const dispatchResult = next(action);
      // updateStoredShoppingBag({ affId: action.payload.affId });
      setItem(STORAGE_TYPE_COOKIE, STORAGE_KEY_AFFILIATE, action.payload.affId, {
        expires: new Date(Date.now() + MONTH),
        path: '/',
      });
      return dispatchResult;
    }
    case HIDE_MNP:
    case REGISTER_ORDER_BY_AGENT:
    case REGISTER_CID: {
      const dispatchResult = next(action);
      dispatch(updateShoppingBag({ ...action.payload }));
      return dispatchResult;
    }
    case REGISTER_VOID: {
      const dispatchResult = next(action);
      dispatch(updateShoppingBag({ ...action.payload }));
      return dispatchResult;
    }
    case REMOVE_PROMOTION_CODES: {
      const dispatchResult = next(action);
      dispatch(updateShoppingBag({ promoCodes: [] }));
      return dispatchResult;
    }
    case REMOVE_CAMPAIGN_TOKEN: {
      const dispatchResult = next(action);
      dispatch(clearCampaignToken());
      return dispatchResult;
    }
    case REGISTER_DENIED_PROMOTIONS: {
      const dispatchResult = next(action);
      const shoppingBag = getItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_SHOPPINGBAG) || {};
      if (shoppingBag.promoCodes && shoppingBag.promoCodes.length) {
        dispatch(registerPromoCodes(shoppingBag.promoCodes));
      }
      return dispatchResult;
    }
    case CLEAR_SHOPPINGBAG:
      removeItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_SHOPPINGBAG);
      break;
    case UPDATE_SHOPPINGBAG: {
      const dispatchResult = next(action);
      const { storage } = getState();
      const { shoppingBag } = storage;

      if (!storage.isSynced) {
        return dispatchResult;
      }

      const value = {
        ...shoppingBag,
        ...action.payload,
      };

      setItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_SHOPPINGBAG, value, {
        expires: new Date(Date.now() + (DAY * 30)),
      });
      return dispatchResult;
    }
    case UPDATE_CAMPAIGN_TOKEN: {
      const dispatchResult = next(action);
      const { storage } = getState();
      const { campaignToken } = storage;

      if (!storage.isSynced) {
        return dispatchResult;
      }

      const value = {
        ...campaignToken,
        ...action.payload,
      };

      setItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CAMPAIGN_TOKEN, value, {
        expires: new Date(Date.now() + (HOUR * 2)),
      });

      return dispatchResult;
    }
    case CLEAR_CAMPAIGN_TOKEN: {
      dispatch(registerCId(null));
      removeItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CAMPAIGN_TOKEN);
      break;
    }
    case UPDATE_CART_SIM_TYPE: {
      const dispatchResult = next(action);
      const { storage } = getState();
      const { cartSimType } = storage;
      if (!storage.isSynced) {
        return dispatchResult;
      }

      const value = {
        ...cartSimType,
        ...action.payload,
      };

      setItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CART_SIM_TYPE, value, {
        expires: new Date(Date.now() + (DAY * 30)),
      });

      return dispatchResult;
    }
    case CLEAR_CART_SIM_TYPE: {
      removeItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CART_SIM_TYPE);
      break;
    }
    case SYNC_STORAGE: {
      // before we sync the cart, we need to ensure, that the user did not put tariffs in the cart
      // that may only be valid in contract renewal mode and are not available in presales.
      const shoppingBag = getItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_SHOPPINGBAG) || {};
      const campaignToken = getItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CAMPAIGN_TOKEN) || {};
      const cartSimType = getItem(STORAGE_TYPE_LOCAL_STORAGE, STORAGE_KEY_CART_SIM_TYPE) || {};
      const { routing } = getState();
      const { locationBeforeTransitions } = routing;
      const { query } = locationBeforeTransitions;

      if (shoppingBag.bId) {
        dispatch(registerVoId(shoppingBag.bId));
      }

      if (shoppingBag.cId) {
        dispatch(registerCId(shoppingBag.cId));
      }

      if (shoppingBag.orderByAgent) {
        dispatch(orderByAgent(shoppingBag.orderByAgent));
      }

      if (shoppingBag.hideMnp) {
        dispatch(hideMnp(shoppingBag.hideMnp));
      }

      if (shoppingBag.entities) {
        dispatch(replaceCart(shoppingBag.entities));
      }

      if (shoppingBag.promoCodes) {
        dispatch(registerPromoCodes(shoppingBag.promoCodes));

        if (query.campaignToken) {
          dispatch(registerCampaignToken([query.campaignToken]));
        }
      }

      // We should not sync the campaignToken if promoCode is url query
      // to ensure only one of them and corresponding promo is used.
      // That time the promoCode query always wins ;)
      if (campaignToken.campaignToken && !query.promoCode) {
        if (query.campaignToken) {
          // We need to ensure that the corresponding promo to the current campaignToken is used
          // the current campaignToken should always override the last stored campaignToken
          // even the current campaignToken is invalid.
          if (query.campaignToken !== campaignToken.campaignToken[0]) {
            dispatch(registerCampaignToken([query.campaignToken]));
          } else {
            dispatch(registerCampaignToken(campaignToken.campaignToken));
          }
        } else {
          dispatch(registerCampaignToken(campaignToken.campaignToken));
        }
      }

      if (cartSimType.cartSimType) {
        dispatch(updateCartSimType(cartSimType.cartSimType));
      }

      return next(action);
    }
    default:
      return next(action);
  }
};

export default storageMiddleware;
