import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import GlobalSection from '../../components/basics/global/GlobalSection';
import CheckoutCartComposition from '../../components/compositions/checkout/CheckoutCart';
import Headline from '../../components/basics/text/TextHeadline';
import enrichCartEntities from '../preloader/PreloaderCart';
import { updateCartSimType } from '../../actions/order/cart';

function CheckoutCart({
  params,
  isValidCart,
  ui,
  cartHardware,
  cartTariff,
  onSimTypeOptionChange,
  cartSimType,
}) {
  return (
    <GlobalSection>
      {!isValidCart &&
        <Headline size="l" utilities={['alignCenter']} embedded >
          {ui.guiEmptyCart}
        </Headline>
      }
      {isValidCart &&
        <CheckoutCartComposition
          headline={params.headline}
          urlSubmit={params.urlCheckout}
          cartHardware={cartHardware}
          cartTariff={cartTariff}
          onSimTypeOptionChange={onSimTypeOptionChange}
          cartSimType={cartSimType}
        />
      }
    </GlobalSection>
  );
}

CheckoutCart.propTypes = {
  params: PropTypes.object.isRequired,
  isValidCart: PropTypes.bool,
  ui: PropTypes.object.isRequired,
  cartHardware: PropTypes.shape({
    iid: PropTypes.string,
  }),
  cartTariff: PropTypes.object,
  onSimTypeOptionChange: PropTypes.func,
  cartSimType: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const { cartTariff } = ownProps;
  const { user } = state;
  return {
    isValidCart: !!cartTariff,
    ui: state.ui,
    cartSimType: user.cartSimType,
  };
}

const mapDispatchToProps = dispatch => ({
  onSimTypeOptionChange: (simType) => {
    dispatch(updateCartSimType(simType));
  },
});

export default compose(
  enrichCartEntities(),
  connect(mapStateToProps, mapDispatchToProps),
)(CheckoutCart);
