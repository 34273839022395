import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import suitcss from '../../helpers/suitcss';
import { showCustomerServiceDialog } from '../../actions/dialog/misc';
import ButtonCustomerService from '../../components/basics/button/ButtonCustomerService';
// import { trackContact } from '../../actions/tracking/event';

class GlobalCustomerService extends PureComponent {
  render() {
    const { ui, dispatch } = this.props;


    const onClickHandling = () => {
      /*
      dispatch(trackContact(
        {
          contact_action: 'reveal',
          contact_subject: 'contact support',
          contact_available_tools: ['hotline', 'faq', 'form', 'chat'],
          contact_tool: 'contact options',
          contact_trigger: 'flyout',
        },
      ));
      */
      dispatch(showCustomerServiceDialog());
    };

    return (
      <div className={suitcss({}, this)}>
        <ButtonCustomerService onClick={onClickHandling}>
          {ui.fcbContactButton}
        </ButtonCustomerService>
      </div>
    );
  }
}

GlobalCustomerService.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    guiWordCancel: PropTypes.string,
  }),
};

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps)(GlobalCustomerService);
