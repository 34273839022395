import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push, replace } from 'react-router-redux';
import GlobalSection from '../../components/basics/global/GlobalSection';
import HardwareTariffsComposition from '../../components/compositions/hardware/HardwareTariffs';
import matchMediaConnector from '../service/ServiceMatchMedia';
import { QUERY_SELECTED_TARIFF, QUERY_SELECTED_HARDWARE } from '../../helpers/constants';
import { selectItem } from '../../actions/hardware/index';
import { selectTariff } from '../../actions/tariff/index';
import {
  getShippingFee,
  isHardwareEntity,
  isHardwareGroup,
  isTariffEntity,
  sortByAvailablePriority,
} from '../../helpers/entity';
import * as cartActions from '../../actions/order/cart';
import {
  shape as hardwareShape,
  list as hardwareListPropType,
} from '../../propTypes/hardware';
import {
  shape as tariffShape,
  list as tariffListPropType,
} from '../../propTypes/tariff';
import { getAvailableTariffs, getEntityById } from '../../selectors/misc';
import { fetchEntitiesByIds } from '../../actions/request/registry';
import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';
import { trackCurrentPage } from '../../actions/tracking/page';

class HardwareTariffs extends PureComponent {

  onClickTabItem = (hash) => {
    const { dispatch, location } = this.props;
    dispatch(replace(`${location.pathname}#${hash}`));
    dispatch(trackCurrentPage());
  };

  getTariffEntitiesAndLabel() {
    const { params, tariffList } = this.props;
    const { tabs } = params;

    return tabs.map(tab => {
      const temp = {};
      temp.label = tab.label;
      temp.tariffs = tab.entities.filter(entity => entity.etype === 'tariff_entity')
        .map(tariff => tariffList.find((entry) => entry.eid === tariff.eid));
      return temp;
    });
  }

  render() {
    const {
      params,
      moduleId,
      primaryModule,
      hardwareName,
      hardwareList,
      tariffList,
      selectedTariff,
      selectedHardware,
      onHardwareChange,
      onSelect,
      onSubmit,
      isMediaS,
      isMediaSM,
      isMediaML,
      isNew,
      location,
      colorScheme,
      dispatch,
      shippingFee,
    } = this.props;

    return (
      <GlobalSection
        layout="contained"
        imageAlign="left"
      >
        <HardwareTariffsComposition
          moduleId={moduleId}
          primaryModule={primaryModule}
          headline={params.headline}
          footer={params.footer}
          hardwareName={hardwareName}
          hardwareList={hardwareList}
          tariffList={tariffList}
          selectedTariff={selectedTariff}
          selectedHardware={selectedHardware}
          onSelect={onSelect}
          onSubmit={onSubmit}
          onHardwareChange={onHardwareChange}
          isMediaS={isMediaS}
          isMediaSM={isMediaSM}
          isMediaML={isMediaML}
          isNew={isNew}
          location={location}
          colorScheme={colorScheme}
          onClickTabItem={this.onClickTabItem}
          tabs={this.getTariffEntitiesAndLabel()}
          dispatch={dispatch}
          shippingFee={shippingFee}
        />
      </GlobalSection>
    );
  }
}

HardwareTariffs.propTypes = {
  moduleId: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  tariffList: tariffListPropType.isRequired,
  hardwareName: PropTypes.string.isRequired,
  hardwareList: hardwareListPropType.isRequired,
  primaryModule: PropTypes.bool.isRequired,
  selectedTariff: tariffShape,
  selectedHardware: hardwareShape,
  onHardwareChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isMediaS: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  isMediaML: PropTypes.bool,
  isNew: PropTypes.bool,
  location: PropTypes.object,
  colorScheme: PropTypes.oneOf(['dark', 'medium', 'light']),
  dispatch: PropTypes.func.isRequired,
  shippingFee: PropTypes.object,
};

HardwareTariffs.getSelectedProduct = (state, { entities, location }) => {
  const hardwareList = entities.filter(isHardwareEntity).sort(sortByAvailablePriority);
  const tariffList = getAvailableTariffs(state.site, state.user, entities.filter(isTariffEntity));

  const selectedHardware = hardwareList.find(
    ({ eid }) => eid === location.query[QUERY_SELECTED_HARDWARE],
  );
  const selectedTariff = tariffList.find(
    ({ eid }) => eid === location.query[QUERY_SELECTED_TARIFF],
  );
  return {
    selectedTariff: selectedTariff || tariffList[0],
    selectedHardware: selectedHardware || hardwareList[0],
  };
};

function mapStateToProps() {
  const normalizeTargetEntitiesSelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  return (state, ownProps) => {
    const { site } = state;
    const { colorScheme } = ownProps.params;

    // first item in tariffs is implicitly selected if no query exists
    // boolean is set to adopt behavior in selector
    const firstSelected = true;
    const entities = normalizeTargetEntitiesSelector(state, ownProps.entities, firstSelected);
    const isContractRenewal = site.contractRenewal.isInProgress;
    const hardwareGroup = entities.find(isHardwareGroup);
    const hardwareName = `${hardwareGroup.brand} ${hardwareGroup.name}`;
    const hardwareList = entities.filter(isHardwareEntity).sort(sortByAvailablePriority);
    const tariffList = getAvailableTariffs(
      state.site, state.user, entities.filter(isTariffEntity),
    );
    const {
      selectedTariff,
      selectedHardware,
    } = HardwareTariffs.getSelectedProduct(state, { ...ownProps, entities });
    const urlSubmit = isContractRenewal
      ? site.sitemap.CheckoutFormRoute.url
      : site.sitemap.ShoppingCartRoute.url;
    const isNew = hardwareGroup.new;
    // get tariff entity
    const selectedTariffEntity = getEntityById(state.entities, selectedTariff.eid);
    const shippingFee = getShippingFee(
      selectedHardware,
      selectedTariffEntity,
      state.entities.tariffVO,
      isContractRenewal,
    );
    return {
      hardwareName,
      hardwareList,
      tariffList,
      selectedTariff,
      selectedHardware,
      urlSubmit,
      isNew,
      colorScheme,
      shippingFee,
    };
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onHardwareChange: entity => dispatch(selectItem(ownProps.location, entity)),
  onSelect: entity => dispatch(selectTariff(ownProps.location, entity)),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { urlSubmit } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onSubmit: async (items) => {
      // the items we get passed are not real entities, they were transformed any may not
      // contain all properties of the original entities. hence we need to fetch the real
      // entities again (or retrieve them from the store).
      const entities = await dispatch(fetchEntitiesByIds(items.map(item => item.eid)));
      dispatch(cartActions.replaceCart(entities));
      dispatch(push(urlSubmit));
    },
  };
};

const Container = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  matchMediaConnector(['isMediaS', 'isMediaSM']),
)(HardwareTariffs);
Container.getSelectedProduct = HardwareTariffs.getSelectedProduct;

export default Container;
