import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import Link from '../../basics/text/TextLink';
import TextHeadline from '../../basics/text/TextHeadline';
import TextInfo from '../../basics/text/TextInfo';
import { hideDialog } from '../../../actions/page/dialog';
import MediaImageBackground from '../../basics/media/MediaImageBackground';
import ServiceLottieAnimation from '../../basics/service/ServiceLottieAnimation';


class ContentStage extends PureComponent {
  constructor(props) {
    super(props);
    this.isMediaS = this.props.isMediaS;
    this.state = {
      isAnimationCorrupted: false,
    };
    this.animationErrorHandling = this.animationErrorHandling.bind(this);
  }

  animationErrorHandling(error) {
    console.error(`ContentStage: Animation -> ${error}`);
    this.setState({
      isAnimationCorrupted: true,
    });
  }

  //------------------------------------------------------------------------------------------------
  //  Helper Methods: For rendering parts of the Component
  //------------------------------------------------------------------------------------------------

  /* eslint-disable-next-line class-methods-use-this */
  prepareBackgroundStyling(stage) {
    const { backgroundAlignment, backgroundColorValue, backgroundGradient } = stage;
    let setBackgroundColor = { backgroundColor: '#fff' };

    // compute background Color
    if (backgroundGradient) {
      setBackgroundColor = { background: backgroundGradient };
    } else if (backgroundColorValue) {
      setBackgroundColor = { backgroundColor: backgroundColorValue };
    }

    return {
      backgroundRepeat: 'no-repeat',
      ...setBackgroundColor,
      backgroundPosition: backgroundAlignment,
      backgroundSize: 'cover',
      borderRadius: '6px',
      overflow: 'hidden',
      color: 'black',
    };
  }

  renderInfoIHint(stage) {
    const {
      noteDialogButton,
      noteDialogCopy,
      noteDialogHeadline,
      noteText,
      buttonPrimary,
      buttonSecondary,
    } = stage;

    if (!noteDialogHeadline) {
      return (
        <Link
          className={
            suitcss({
              descendantName: 'header',
              modifiers: ['simpleHint'],
            }, this)}
          withoutArrow
          withoutStyle
          href={buttonPrimary.url || buttonSecondary.url}
        >{noteText}
        </Link>
      );
    }
    return (
      <TextInfo
        label={noteText}
        copy={noteDialogCopy}
        dialog={{
          headline: noteDialogHeadline,
          copy: noteDialogCopy,
          actions: [{
            label: noteDialogButton,
            action: () => { dispatch(hideDialog()); } }] }} // eslint-disable-line
      />
    );
  }

  renderHeadline(stage) {
    const { headline, buttonPrimary, buttonSecondary } = stage;

    return (
      <Link
        className={
          suitcss({
            descendantName: 'link',
          }, this)}
        withoutArrow
        withoutStyle
        noHover
        href={buttonPrimary.url || buttonSecondary.url}
      >
        <TextHeadline embedded element="h2" size="xl" raw>
          {headline}
        </TextHeadline>
      </Link>
    );
  }

  renderHeader(stage) {
    const { noteText } = stage;

    return (
      <header
        className={
          suitcss({
            descendantName: 'header',
          }, this)}
      >
        {
          noteText &&
          this.renderInfoIHint(stage)
        }
        {this.renderHeadline(stage)}
      </header>
    );
  }

  renderCopyLine(stage) {
    const { subline, buttonPrimary, buttonSecondary } = stage;

    return (
      <Link
        className={suitcss({
          descendantName: 'copyLine',
        }, this)}
        withoutArrow
        withoutStyle
        noHover
        href={buttonPrimary.url || buttonSecondary.url}
      >
        <p>{subline}</p>
      </Link>
    );
  }

  renderButtons(stage) {
    const { buttonPrimary, buttonSecondary, buttonPrimaryToggle } = stage;

    return (
      <footer
        className={suitcss({
          descendantName: 'footer',
        }, this)}
      >
        {buttonPrimary &&
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <Link
          to={buttonPrimary.url}
          asButton
          buttonFilled
          withoutArrow
          highlight={buttonPrimaryToggle}
        >
          {buttonPrimary.title}
        </Link>
        }
        {buttonSecondary &&
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <Link to={buttonSecondary.url} asButton withoutArrow>
          {buttonSecondary.title}
        </Link>
        }
      </footer>
    );
  }

  renderMedia(stage) {
    const {
      desktopImage,
      mobileImage,
      contentImgWithFullWidth,
      buttonPrimary,
      buttonSecondary,
    } = stage;
    const contentImg = this.isMediaS && mobileImage ? mobileImage : desktopImage;
    const contentImgSrc = contentImg && `url(${contentImg.src})`;
    const style = {
      backgroundImage: contentImgSrc,
      backgroundSize: contentImgWithFullWidth ? 'cover' : 'contain',
      backgroundPosition: contentImgWithFullWidth ? 'top' : 'bottom',
      backgroundRepeat: 'no-repeat',
    };

    return (
      <a
        className={suitcss({
          descendantName: 'media',
        }, this)}
        style={contentImgSrc && style}
        href={buttonPrimary.url || buttonSecondary.url}
      > {}
      </a>
    );
  }

  renderLottieAnimation(stage, errorCallback) {
    const {
      desktopAnimation,
      desktopFullWidth,
      mobileFullWidth,
      animationLoop,
      buttonPrimary,
      buttonSecondary,
    } = stage;
    const size = (!this.isMediaS && desktopFullWidth) || (this.isMediaS && mobileFullWidth) ? 'cover' : '';
    const alignV = (!this.isMediaS && desktopFullWidth) || (this.isMediaS && mobileFullWidth) ? '' : 'bottom';

    return (
      <a
        className={suitcss({
          descendantName: 'media',
        }, this)}
        href={buttonPrimary.url || buttonSecondary.url}
      >
        <ServiceLottieAnimation
          loop={animationLoop}
          path={desktopAnimation}
          size={size}
          alignV={alignV}
          errorCallback={errorCallback}
        />
      </a>
    );
  }

  renderTextContent(stage) {
    const { subline } = stage;

    return (
      <article
        className={suitcss({
          descendantName: 'Text-content',
        }, this)}
      >
        {this.renderHeader(stage)}
        {subline && this.renderCopyLine(stage)}
        {this.renderButtons(stage)}
      </article>
    );
  }

  renderContent(stage) {
    const { desktopAnimation } = stage;

    return (
      <Fragment>
        {this.renderTextContent(stage)}
        {desktopAnimation && !this.state.isAnimationCorrupted ?
          this.renderLottieAnimation(stage, this.animationErrorHandling) :
          this.renderMedia(stage)}
      </Fragment>
    );
  }

  renderImageOverGradient(stage) {
    const { backgroundImage, backgroundImageMobile, backgroundAlignment } = stage;
    const bgImage = this.isMediaS && backgroundImageMobile ?
      backgroundImageMobile : backgroundImage;
    const bgImageSrc = bgImage && bgImage.src;
    const style = {
      backgroundSize: 'cover',
      backgroundPosition: backgroundAlignment || 'bottom',
      backgroundRepeat: 'no-repeat',
    };

    return (
      <MediaImageBackground
        className={suitcss({
          descendantName: 'container',
          modifiers: ['ImageOverGradient',
          ],
        }, this)}
        style={style}
        src={bgImageSrc}
      />
    );
  }


  //------------------------------------------------------------------------------------------------
  //  Rendering for the contentStage Component
  //------------------------------------------------------------------------------------------------

  renderContentStageItem(stage, index) {
    const {
      desktopFullWidth,
      mobileFullWidth,
      noteDialogCopy,
      backgroundImage,
      backgroundImageMobile,
      backgroundGradient,
    } = stage;

    // eslint-disable-next-line no-param-reassign
    stage.contentImgWithFullWidth = this.isMediaS ? mobileFullWidth : desktopFullWidth;
    const bgImage = this.isMediaS && backgroundImageMobile ?
      backgroundImageMobile : backgroundImage;
    const bgImageSrc = bgImage && bgImage.src;
    const backgroundStyling = this.prepareBackgroundStyling(stage);
    const hasNoNoteDialogCopy = !noteDialogCopy;

    return (
      <MediaImageBackground
        className={suitcss({
          descendantName: 'container',
          modifiers: [
            hasNoNoteDialogCopy && 'withNoHint',
            stage.contentImgWithFullWidth && 'withWithFullWidthImg',
          ],
        }, this)}
        style={backgroundStyling}
        src={bgImage && bgImageSrc}
        key={index}
      >
        {!!backgroundGradient && bgImage &&
        this.renderImageOverGradient(stage)}
        {this.renderContent(stage)}
      </MediaImageBackground>
    );
  }

  renderSplitContentStage(stage, index) {
    return (
      <div
        className={suitcss({
        descendantName: 'splitContentStageItem',
        }, this)}
        key={index}
      >
        {this.renderContentStageItem(stage, index)}
      </div>
    );
  }

  renderContentStage() {
    const { stages } = this.props.params;
    let contentStage;
    if (stages.length > 1) {
      contentStage = stages.map((stage, index) => this.renderSplitContentStage(stage, index));
    } else {
      contentStage = stages.map((stage, index) => this.renderContentStageItem(stage, index));
    }

    return contentStage;
  }

  render() {
    const { stages, colorScheme } = this.props.params;
    const isSplitContentStage = stages.length > 1;

    return (
      <section className={suitcss({
        modifiers: [isSplitContentStage ? 'dividedContentStage' : '', colorScheme],
      }, this)}
      >
        {
          this.renderContentStage(stages)
        }
      </section>
    );
  }
}

//------------------------------------------------------------------------------------------------
//  contentStage Proptypes
//------------------------------------------------------------------------------------------------

ContentStage.propTypes = {
  isMediaS: PropTypes.bool,
  params: PropTypes.shape({
    align: PropTypes.string.isRequired,
    colorScheme: PropTypes.oneOf(['dark', 'light']),
    config: PropTypes.array.isRequired,
    stage: PropTypes.shape({
      backgroundImage: PropTypes.string.isRequired,
      backgroundImageMobile: PropTypes.string.isRequired,
      buttonPrimary: PropTypes.object.isRequired,
      buttonSecondary: PropTypes.object.isRequired,
      buttonPrimaryToggle: PropTypes.bool.isRequired,
      desktopImage: PropTypes.object.isRequired,
      desktopFullWidth: PropTypes.bool.isRequired,
      headline: PropTypes.string.isRequired,
      mobileImage: PropTypes.object.isRequired,
      mobileFullWidth: PropTypes.bool.isRequired,
      noteDialogButton: PropTypes.string.isRequired,
      noteDialogCopy: PropTypes.string.isRequired,
      noteDialogHeadline: PropTypes.string.isRequired,
      subline: PropTypes.string.isRequired,
      desktopAnimation: PropTypes.string,
      animationLoop: PropTypes.bool,
      autoplay: PropTypes.bool,
    }),
    stages: PropTypes.array,
    errorCallback: PropTypes.func,
  }).isRequired,
};

export default ContentStage;
