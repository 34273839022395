export const ACTION_PREFIX = '@@otelo-web';

export const ROUTE_PREFIX_MY_OTELO = '/mein-otelo/';

export const ENTITY_TYPE_TARIFF = 'tariffEntity';
export const ENTITY_TYPE_TARIFF_VO = 'tariffVO';
export const ENTITY_TYPE_TARIFFOPTION = 'tariffOptionEntity';
export const ENTITY_TYPE_TARIFFOPTION_VO = 'tariffOptionVO';
export const ENTITY_TYPE_HARDWARE = 'hardwareEntity';
export const ENTITY_TYPE_HARDWAREGROUP = 'hardwareGroup';
export const ENTITY_TYPE_TARIFF_DETAILS = 'tariffDetail';
export const ENTITY_TYPE_HARDWARE_DETAILS = 'hardwareDetails';
export const ENTITY_TYPE_PROMOTION_DETAILS = 'myPromoDetails';
export const ENTITY_TYPE_TEASER_BAR = 'teaserBar';

export const VO_TYPE_TARIFF = 'tariff';
export const VO_TYPE_TARIFFOPTION = 'tariff_option';
export const VO_TYPE_PROMOTION = 'promotion';
export const VO_TYPE_ACTIVE_PROMOTION = 'active_promotion';
export const VO_TYPE_HARDWARE = 'hardware';

export const VO_STATE_TERMINATED = 'terminated';
export const VO_STATE_CANCELED = 'canceled';
export const VO_STATE_ACTIVE = 'active';
export const VO_STATE_BOOKED = 'booked';
export const VO_STATE_BOOKEDSINGLE = 'booked_single';
export const VO_STATE_CHANGING = 'in_change';
export const VO_STATE_PROCESSING = 'in_process';
export const VO_STATE_PAUSED = 'paused';

export const CONSUMPTION_TYPE_DATA = 'data';
export const CONSUMPTION_TYPE_SMS = 'sms';
export const CONSUMPTION_TYPE_MIN = 'min';
export const CONSUMPTION_TYPE_UNIT = 'unit';
export const CONSUMPTION_TYPE_VOLUME = 'volume';

export const NOTIFICATION_TYPE_LOW_VOLUME = 'low-volume';
export const NOTIFICATION_TYPE_LOW_BALANCE = 'low-balance';
export const NOTIFICATION_TYPE_PAUSED = 'paused-tariff-option';

export const BILLING_DELIVERY_VALIDATION_STATE_CREATED = 'created';
export const BILLING_DELIVERY_VALIDATION_STATE_REMEMBERED = 'remembered';
export const BILLING_DELIVERY_VALIDATION_STATE_NOT_VALIDATED = 'not_validated';
export const BILLING_DELIVERY_VALIDATION_STATE_VALID = 'valid';
export const BILLING_DELIVERY_VALIDATION_STATE_NONE = 'none';

/* Dialog types */
export const DIALOG_TYPE_QUEUED = Symbol('DIALOG_TYPE_QUEUED');
export const DIALOG_TYPE_CUSTOM = Symbol('DIALOG_TYPE_CUSTOM');

/* Notification types */
export const NOTIFICATION_TYPE_DEFAULT = Symbol('NOTIFICATION_TYPE_DEFAULT');
export const NOTIFICATION_TYPE_CUSTOM = Symbol('NOTIFICATION_TYPE_CUSTOM');
export const NOTIFICATION_TYPE_ALERT = Symbol('NOTIFICATION_TYPE_ALERT');

/* Custom Event Types */
export const CUSTOM_EVENT_RESIZE = 'CUSTOM_EVENT_RESIZE';
export const CUSTOM_EVENT_EQUAL_RESIZE = 'CUSTOM_EVENT_EQUAL_RESIZE';

/* Page / Module Config Keys */
export const MODULE_CONFIG_LAYOUT = 'layoutSettings';
export const MODULE_CONFIG_ANIMATION = 'animationSettings';
export const MODULE_CONFIG_BEHAVIOR = 'behaviorSettings';

/* Response Meta Message types */
export const META_MESSAGE_TYPE_PAGE = 'page';
export const META_MESSAGE_TYPE_DIALOG = 'dialog';

/*
=======================================================================================
MENU'S - STORE IDENTIFIERS
used for identifing menus and there states eg. Opened / Closed
@todo - refactor / add identifiers for menus not listed
=======================================================================================
*/
export const MENU_IDENTIFIER_MAIN = 'otelo-nav-main';
export const MENU_IDENTIFIER_FOOTER = 'otelo-nav-footer';
export const MENU_IDENTIFIER_META = 'otelo-nav-meta';
export const MENU_IDENTIFIER_ACCOUNT = 'otelo-nav-myotelo';
export const MENU_IDENTIFIER_MY_OTELO = 'otelo-nav-myotelo-main';
export const MENU_IDENTIFIER_MY_OTELO_PUA = 'otelo-nav-myotelo-main-pua';
export const MENU_IDENTIFIER_CART = 'otelo-nav-cart';
export const MENU_CMS_SEARCH_SUGGESTIONS = 'otelo-nav-search-global';
export const MENU_CMS_SEARCH_SUGGESTIONS_SERVICE = 'otelo-nav-search-service';

export const PRIVATE_PROMO_CODE_PREFIX = 'SECRET_';

/*
=======================================================================================
The main context the site is currently in.
Note: do not(!) change the values as they correspond to values in the promo engine
@see https://confluence.db-n.com/x/WODc
=======================================================================================
*/

export const CONTEXT_REGISTRATION = 'registerMember';
export const CONTEXT_ACTIVATION = 'activation';
export const CONTEXT_CONTRACT_RENEWAL = 'renewContract';
export const CONTEXT_CONTRACT_RENEWAL_SIM_PLUS = 'renewContractSimPlus';
export const CONTEXT_TARIFF_CHANGE = 'changeTariff';
export const CONTEXT_TARIFF_OPTION = 'bookTariffoption';

/*
=======================================================================================
BILLING_MODE
Defines, whether the user has to pay at the beginning (pre) or at the end (post) of the month
=======================================================================================
*/

export const BILLING_MODE_PRE = 'pre';
export const BILLING_MODE_POST = 'post';

/*
=======================================================================================
BRAND - Specify the branding of the site or the brand of the sim card
=======================================================================================
*/

export const BRAND_ROSSMANN = 'rossmann';
export const BRAND_OTELO = 'otelo';
export const BRAND_ALLMOBIL = 'allmobil';

/*
=======================================================================================
TOPUP TYPES - Identify the way a user's card is charged
=======================================================================================
*/

export const TOPUP_TYPE_SINGLE = Symbol('TOPUP_TYPE_SINGLE');
export const TOPUP_TYPE_AUTO = Symbol('TOPUP_TYPE_AUTO');

/*
=======================================================================================
Ui-element ids - Each id identifies a specific ui collection in the CMS
=======================================================================================
*/

export const UI_COLLECTION_MY_OTELO = 'mein_otelo';
export const UI_COLLECTION_GLOBAL = 'global_ui';
export const UI_COLLECTION_PROMOTION = 'promotion';
export const UI_COLLECTION_GDPR = 'gdpr';
export const UI_COLLECTION_CONSUMPTIONS = 'consumptions';
export const UI_COLLECTION_TARIFFS = 'tariffs';
export const UI_COLLECTION_SELFCARE_CONSENT_LAYER = 'selfcare_consent_layer';
export const UI_COLLECTION_MY_CANCELLATION_FORM = 'my_cancellation_form';
export const UI_COLLECTION_MY_MNP_OUT = 'my_mnp_out';
export const UI_COLLECTION_MY_ACR = 'acr';
export const UI_COLLECTION_SHARED_BUTTONS = 'button';
export const UI_COLLECTION_NBA = 'nba';
export const UI_MY_FRIEND_REFERRAL_DASHBOARD = 'my_friend_referral_dashboard';
export const UI_MY_FRIEND_REFERRAL_GLOBAL = 'my_friend_referral_global';
export const UI_ESIM_DEVICE_CHECK = 'esim_device_check';
export const UI_MY_SIMCARD_DATA = 'my_simcard_data';
export const UI_COLLECTION_ESIM_ACTIVATION = 'my_esim_activation';
export const UI_COLLECTION_ESIM_STATUS = 'my_esim_status';
export const UI_COLLECTION_CAPTCHA_SERVICE = 'captcha_service';
export const UI_BOOKING_OVERVIEW = 'booking_overview';
export const UI_COLLECTION_VVL = 'vvl';

/*
=======================================================================================
MARKETS - Every tariff necessarily belongs to one of the markets in the list
=======================================================================================
*/

export const MARKET_POSTPAID = 'PUC';
export const MARKET_PREPAID = 'PUA';
export const MARKET_MMO = 'MMO';
export const MARKET_ALL = 'ALL';

/*
=======================================================================================
LOGGING - Everything logging related
=======================================================================================
*/

export const LOGGING_SENSIBLE_DATA_KEYS = [
  'password',
  'username',
  'new_password',
  'old_password',
  'password_new',
  'password_confirm',
  'old',
  'new',
  'id_card_no',
  'media_id',
  'origin_owner',
];

/*
=======================================================================================
AVAILABILITY - The areas where this entity should be visible and usable
=======================================================================================
*/

export const AVAILABILITY_PRESALES = 'presales';
export const AVAILABILITY_ACCOUNT = 'account';

/*
=======================================================================================
EVENT TYPES - Received from server via responses' meta property to trigger frontend actions
=======================================================================================
*/

export const EVENT_PROMO_BOOKED_SUCCESS = 'promo_booked_success';
export const EVENT_PAGE_REDIRECT = 'page_redirect';

/*
=======================================================================================
ELEMENT ID's - Used to identify / define a markup element's id attribute
=======================================================================================
*/

export const ID_MY_OFFERS_TARIFFS = 'MyOffersTariffs';
export const ID_MY_OFFERS_OPTIONS = 'MyOffersOptions';
export const ID_MY_OFFERS_PROMOTIONS = 'MyOffersPromotions';
export const ID_TARIFF_DETAILS = 'tariffDetails';
export const ID_HARDWARE_DETAILS = 'hardwareDetails';
export const ID_FOOTER_LEGALS = 'Legals';

/*
=======================================================================================
TAGS - Used to identify / define a products category
=======================================================================================
*/

export const TAG_MY_TARIFF = 'myTariff';

/*
=======================================================================================
QUERY PARAMS - Used to identify globally used query parameter in our urls
=======================================================================================
*/

export const QUERY_ORDER_ID = 'orderId';
export const QUERY_VOID = 'b_id';
export const QUERY_CID = 'c_id';
export const QUERY_AFFILIATE_ID = 'aff_id';
export const QUERY_ENTITY_ID = 'eid';
export const QUERY_INTERNAL_ID = 'iid';
export const QUERY_APPVIEW = 'appview';
export const QUERY_APPVIEW_CONTEXT = 'appcontext';
export const QUERY_INBOXVIEW = 'inboxview';
export const QUERY_PASSW_RESET_TOKEN = 'email_token';
export const QUERY_FORM_STEP = 'step';
export const QUERY_SELECTED_HARDWARE = 'selectedHardware';
export const QUERY_SELECTED_TARIFF = 'selectedTariff';
export const QUERY_SELECTED_TARIFF_OPTION = 'selectedTariffOption';
export const QUERY_CONTR_RENEWAL_START = 'start';
export const QUERY_CONTR_RENEWAL_TARIFF_ID = 'tariffId';
export const QUERY_CONTR_RENEWAL_HARDWARE_ID = 'hardwareId';
export const QUERY_FILTERED_HW_BRAND = 'brand';
export const QUERY_FILTERED_HW_CATEGORIES = 'categories';
export const QUERY_FILTERED_BLOG_HASHTAGS = 'tags';
export const QUERY_EDIT = 'edit';
export const QUERY_CUSTOMER_DATA = 'customerData';
export const QUERY_PAYMENT_DATA = 'paymentData';
export const QUERY_NPS_CREATION_DATE = 't';
export const QUERY_NPS_OPTPARAM = 'optparam';
export const QUERY_NPS_OPTPARAM_LEGACY = 'opt_param';
export const QUERY_NPS_SOLICITOR_ID = 'solicitorId';
export const QUERY_ORDERING_HOTLINE = 'ordering-hotline';
export const QUERY_ACTIVATION_MSISDN = 'msisdn';
export const QUERY_PPLUS = 'pplus';
export const QUERY_PROMO_CODE = 'promoCode';
export const QUERY_FILL_CART = 'fillCart';
export const QUERY_IS_BOOKED = 'booked';
export const QUERY_IDENT_METHOD = 'identMethod';
export const QUERY_IDENT_PROVIDER = 'identProvider';
export const QUERY_ORDER_BY_AGENT = 'order_by_agent';
export const QUERY_HIDE_MNP = 'hidemnp';
export const QUERY_COOKIE_CONSENT = 'set-consent';
export const QUERY_TARIFF_MODE = 'tariffMode';
export const QUERY_APP_DARK_MODE = 'darkMode';
export const QUERY_DO_NOT_TRACK = 'DNT';
export const QUERY_CAMPAIGN_TOKEN = 'campaignToken';

/*
=======================================================================================
SIM STATUS CODES - specify the current state of a user's sim card
=======================================================================================
*/

export const PREACTIVE_SIM = 'PRE';
export const ASSIGNED_SIM = 'ASS';
export const ACTIVE_SIM = 'ACT';
export const PASSIVE_SIM = 'PAS';
export const SUSPENDED_SIM = 'SUS';
export const INACTIVE_SIM = 'INA';
export const STATUS_PREACTIVE = 'preactive';

/*
=======================================================================================
ESIM STATUS - specify the current state of a user's eSIM
=======================================================================================
*/

export const READY_FOR_DOWNLOAD = 'READY_FOR_DOWNLOAD';
export const INSTALLED = 'INSTALLED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS';
export const UNAVAILABLE = 'UNAVAILABLE';
export const PUSH_ASSIGN = 'PUSH_ASSIGN';
export const PUSH_DEASSIGN = 'PUSH_DEASSIGN';


/*
=======================================================================================
SIM Types
=======================================================================================
*/

export const SIM_CARD = 'SIM_CARD';
export const E_SIM = 'E_SIM';

/*
=======================================================================================
INLINE_ERROR - Types of inline errors
=======================================================================================
*/

// @todo use these constants everywhere in our project
export const INLINE_ERROR_VALUE_MISSING = 'valueMissing';
export const INLINE_ERROR_VALUE_MISMATCH = 'valueMismatch';
export const INLINE_ERROR_VALUE_RANGE_MISMATCH = 'rangeMismatch';
export const INLINE_ERROR_VALUE_PATTERN_MISMATCH = 'patternMismatch';
export const INLINE_ERROR_VALUE_EQUALITY_MISMATCH = 'equalityMismatch';
export const INLINE_ERROR_VALUE_AGE_UNDERFLOW = 'ageUnderflow';
export const INLINE_ERROR_VALUE_AGE_OVERFLOW = 'ageOverflow';
export const INLINE_ERROR_VALUE_CUSTOM_RULE = 'customRule';
export const INLINE_ERROR_VALUE_GENERIC = 'generic';

/*
=======================================================================================
Payment data
=======================================================================================
*/

export const PAYMENT_DATA_TYPE_CREDIT_CARD = 'cc';
export const PAYMENT_DATA_TYPE_DIRECT_DEBIT = 'debit';
export const PAYMENT_DATA_TYPE_MMO = 'mmo';
export const PAYMENT_DATA_TYPE_NONE = 'none';

/*
=======================================================================================
Payment fee
=======================================================================================
*/

export const PAYMENT_FEE_MONTHLY = 'paymentFee';
export const PAYMENT_FEE_MONTHLY_STRIKE = 'paymentFeeStrike';
export const PAYMENT_FEE_SINGLE = 'singlePaymentFee';
export const PAYMENT_FEE_SINGLE_STRIKE = 'singlePaymentFeeStrike';
export const BASIC_FEE = 'basicFee';
export const BASIC_FEE_STRIKE = 'basicFeeStrike';

/*
=======================================================================================
Consent data
=======================================================================================
*/

export const CONSENT_INQUIRE_DATA_TYPE_BEW = 'inquireAdConsent';
export const CONSENT_INQUIRE_DATA_TYPE_DATA = 'inquirePassData';

/*
=======================================================================================
Storage keys
=======================================================================================
*/

export const STORAGE_KEY_CREDENTIALS = 'credentials';
export const STORAGE_KEY_TEASER_LAYER = 'teaser_layer';
export const STORAGE_KEY_DIALOG_STATES = 'dialogStates';
export const STORAGE_KEY_DOCUMENT_STATES = 'documentStates';
export const STORAGE_KEY_LAST_VISIT = 'last_visit';
export const STORAGE_KEY_AFFILIATE = 'affiliate';
export const STORAGE_KEY_HOTLINE_DEAL = 'hotline_deal';
export const STORAGE_KEY_BELATED_MNP_DIALOG = 'belated_mnp_dialog';
export const STORAGE_KEY_SESSION_ID = 'session_id';
export const STORAGE_KEY_EMAIL = 'email_validation_status';
export const STORAGE_KEY_CONSENT_INQUIRY_DIALOG = 'consent_inquiry_dialog';
export const STORAGE_KEY_UMID = 'umid';
export const STORAGE_KEY_SCOPE = 'scope';
export const STORAGE_KEY_SHOPPINGBAG = 'otl-shoppingbag';
export const STORAGE_KEY_CAMPAIGN_TOKEN = 'otl-campaigntoken';
export const STORAGE_KEY_ESIM = 'esim_activation_status';
export const STORAGE_KEY_CART_SIM_TYPE = 'cart-sim-type';

/*
=======================================================================================
The mechanism by which an error is communicated to the user
=======================================================================================
*/

export const ERROR_DISPLAY_STYLE_PAGE = 'error-page';
export const ERROR_DISPLAY_STYLE_DIALOG = 'dialog';
export const ERROR_DISPLAY_STYLE_ALERT = 'alert';
export const ERROR_DISPLAY_STYLE_FORM = 'form-error-bar';
export const ERROR_DISPLAY_STYLE_HIDDEN = 'hidden';

/*
=======================================================================================
Request methods
=======================================================================================
*/

export const REQUEST_METHOD_GET = 'GET';
export const REQUEST_METHOD_PUT = 'PUT';
export const REQUEST_METHOD_DELETE = 'DELETE';
export const REQUEST_METHOD_POST = 'POST';
export const REQUEST_METHOD_PATCH = 'PATCH';

/*
=======================================================================================
IDENT CONSTANTS - the types to ident after buying a product
=======================================================================================
*/

export const IDENT_METHOD_DOOR = 'door';
export const IDENT_METHOD_STORE = 'store';
export const IDENT_METHOD_VIDEO = 'video';

/*
=======================================================================================
FORM CONSTANTS - identify special fields or modifiers in relation with forms
=======================================================================================
*/

/**
 * Indicates that this is a field that does not hold data to be submitted.
 */
export const FORM_META_FIELD_PREFIX = '_';

/**
 * Form names that need to be referenced from different files.
 */
export const FORM_NAME_CHECKOUT = 'checkout';

/**
 * Field names that need to be shared accross different forms
 * @todo some of the values below are not names but values; rename into FORM_VALUE_XXX_XYZ
 */
export const FORM_FIELD_SIM_STATUS = `${FORM_META_FIELD_PREFIX}_simStatusResponse`;
export const FORM_FIELD_TOPUP_OPTIONS = `${FORM_META_FIELD_PREFIX}_topupOptions`;
export const FORM_FIELD_CHECKOUT_MNP = 'mnp';

export const FORM_FIELD_PW_RESET_BIRTHDAY_REQUIRED = 'is_birthday_required';

export const FORM_FIELD_EVN_STATUS = 'status';
export const FORM_FIELD_EVN_STATUS_FULL = 'full';
export const FORM_FIELD_EVN_STATUS_SHORT = 'short';
export const FORM_FIELD_EVN_STATUS_DISABLED = 'disabled';

export const FORM_FIELD_ACR_STATUS = 'anonymous_call_rejection';
export const FORM_FIELD_ACR_STATUS_DISABLED = 'false';
export const FORM_FIELD_ACR_STATUS_ENABLED = 'true';

export const FORM_FIELD_ADCONSENT_INFO = 'ad_consent';
export const FORM_FIELD_ADCONSENT_CONTACT = 'pass_data';

export const FORM_FIELD_GDPR_CONSENT_PRI = 'privilege_terms';
export const FORM_FIELD_GDPR_CONSENT_BEW = 'bew_checkout_terms';
export const FORM_FIELD_GDPR_CONSENT_DAT = 'data_handling_terms';
export const FORM_FIELD_GDPR_CONSENT_BLACKLIST = 'selfcare_blacklist';

export const FORM_FIELD_BILLING_DELIVERY = 'billing_delivery';
export const FORM_FIELD_BILLING_DELIVERY_MAIL = 'mail';
export const FORM_FIELD_BILLING_DELIVERY_EMAIL = 'email';
export const FORM_FIELD_BILLING_DELIVERY_ONLINE = 'online';

export const FORM_FIELD_PAYMENT_TYPE = 'payment_type';
export const FORM_FIELD_PAYMENT_TYPE_DEBIT = '01';
export const FORM_FIELD_PAYMENT_TYPE_CC = '04';
export const FORM_FIELD_PAYMENT_TYPE_ADVANCE = '06';
export const FORM_FIELD_PAYMENT_TYPE_PAYPAL = '10';

export const SINGLE_PAYMENT_TYPE_PAYPAL = 'paypal';

export const FORM_FIELD_MNP_PORTING_VTEN = 'VTEN';
export const FORM_FIELD_MNP_PORTING_ASAP = 'ASAP';

export const FORM_FIELD_PASSWORD_CONFIRM = 'password_confirm';

/**
 * The form modes define the possible states a form can be run in. E.g. sim replacement
 * can be run in ordering or activation mode.
 */
export const FORM_MODE_SIM_REPL_ACTIVATION = Symbol('FORM_MODE_ACTIVATION');
export const FORM_MODE_SIM_REPL_ORDERING = Symbol('FORM_MODE_ORDERING');
export const FORM_MODE_SIM_REPL_DISABLED = Symbol('FORM_MODE_DISABLED');

/**
 * Identifiers for different form steps. The identifiers are outsourced as
 * constants to avoid cyclic dependencies when form steps
 */
export const FORM_ID_BMNP_CONFIRM = 'confirm';
export const FORM_ID_BMNP_CONTRACT = 'contract';
export const FORM_ID_BMNP_MSISDN = 'msisdn';
export const FORM_ID_CHECKOUT_SHIPPING = 'shipping';
export const FORM_ID_CHECKOUT_TRANSFER = 'transfer';
export const FORM_ID_CHECKOUT_PAYMENT = 'payment';
export const FORM_ID_CHECKOUT_CONFIRM = 'confirm';
export const FORM_ID_SIM_ACTIVATION_PERSONAL_DATA = 'personalData';
export const FORM_ID_SIM_ACTIVATION_CONFIRM = 'confirm';

/*
=======================================================================================
FORM CONSTANTS - identify special fields or modifiers in relation with forms
====================================================================
*/

export const HEADER_DEFAULT_MIME_APP = 'application/vnd.otelo.myapp-v7.1.0+json';

export const HEADER_DEFAULT_MIME_WEB = 'application/vnd.otelo.myweb-v10.4.0+json';

export const HEADER_KEY_TRANSACTION_ID = 'X-Transaction-ID';

/*
=======================================================================================
TRACKING REPLACE KEYS - Placeholders within tracking values that are to be replaced
=======================================================================================
*/

export const TRACK_REPLACE_REASON = '{REASON}';
export const TRACK_REPLACE_AVATAR_ID = '{AVATAR_ID}';
export const TRACK_REPLACE_PROMOTION_ID = '{PROMOTION_ID}';
export const TRACK_REPLACE_BMNP_STATUS = '{BMNP_STATUS}';
export const TRACK_REPLACE_BMNP_TIMING = '{BMNP_TIMING}';
export const TRACK_REPLACE_SIMREP_TYPE = '{SIMREP_TYPE}';
export const TRACK_REPLACE_ERROR_CODE = '{ERROR_CODE}';
export const TRACK_REPLACE_ANSWER_ID = '{ANSWER_ID}';
export const TRACK_REPLACE_TOPIC_ID = '{TOPIC_ID}';

/*
=======================================================================================
TRACKING CONSTANTS - MyTracking https://confluence.db-n.com/x/4tXc
=======================================================================================
*/
export const MYTRACK_PROPOSITION_CATEGORY_TARIFF = 'tariff';
export const MYTRACK_PROPOSITION_CATEGORY_BUNDLE = 'bundle';
export const MYTRACK_PROPOSITION_CATEGORY_TARIFF_OPTION = 'tariff option';
export const MYTRACK_PROPOSITION_CATEGORY_TOPUP = 'topup';
export const MYTRACK_PROPOSITION_CATEGORY_DEVICE = 'device';
export const MYTRACK_PROPOSITION_CATEGORY_SELECTION = 'selection';

export const MYTRACK_PROPOSITION_PRODUCT_POSTPAID = 'post-paid tariff';
export const MYTRACK_PROPOSITION_PRODUCT_PREPAID = 'pre-paid tariff';

export const MYTRACK_PROPOSITION_TYPE_DEBIT = 'debit';
export const MYTRACK_PROPOSITION_TYPE_CREDIT = 'credit';

export const MYTRACK_PROPOSITION_LINE_VOICE = 'voice';
export const MYTRACK_PROPOSITION_LINE_DATA = 'data';

export const MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_YOUNG = 'young';
export const MYTRACK_PROPOSITION_PRODUCT_TYPE_DEVICE = 'device';

export const MYTRACK_PROPOSITION_PRODUCT_AUDIENCE_CUSTOMER = 'consumer';

export const MYTRACK_PROPOSITION_PRODUCT_DURATION = '24';

export const MYTRACK_PROPOSITION_RETENTION_NEWCONTRACT = 'newcontract';
export const MYTRACK_PROPOSITION_RETENTION_PROLONGATION = 'prolongation';
export const MYTRACK_PROPOSITION_RETENTION_TARIFF_OPTION = 'tariff option';
export const MYTRACK_PROPOSITION_RETENTION_TOPUP = 'topup';

export const MYTRACKING_ATTRIBUTE = 'page_name';

export const MYTRACK_LOGIN_PUC_MSISDN = '1_1_login_puc_msisdn';
export const MYTRACK_LOGIN_PUA_MSISDN = '1_1_login_pua_msisdn';
export const MYTRACK_LOGIN_MMO_MSISDN = '1_1_login_mmo_msisdn';
// These just trigger the password reset workflow
// export const MYTRACK_LOGIN_PUC_SMS = '1_1_login_puc_sms';
// export const MYTRACK_LOGIN_PUA_SMS = '1_1_login_pua_sms';
// export const MYTRACK_LOGIN_MMO_SMS = '1_1_login_mmo_sms';
export const MYTRACK_LOGIN_FORGOT_PASSWORD = '1_1_forgot_password';
export const MYTRACK_LOGIN_CREATE_ACCOUNT = '1_1_create_account';
export const MYTRACK_LOGIN_GIVE_PASSWORD = '1_2_give_password';
export const MYTRACK_LOGIN_GIVE_PASSWORD_SMS = '1_2_give_password_via_SMS';
export const MYTRACK_LOGIN_GIVE_PASSWORD_SMS_SENT = '1_2_SMS_CODE_erfolgreich_versandt';
export const MYTRACK_LOGIN_GIVE_PASSWORD_EMAIL = '1_2_give_password_via_mail';
export const MYTRACK_LOGIN_CONFIRM_SMS_TOKEN = '1_2_confirm_sms_token';
export const MYTRACK_LOGIN_RESEND_SMS_TOKEN = '1_2_sent_new_sms_token';
export const MYTRACK_LOGIN_SAVE_NEW_PASSWORD = '1_2_save_new_password';
export const MYTRACK_LOGIN_CHECK_MSISDN = '1_3_check_msisdn';
export const MYTRACK_LOGIN_DIALOG_GIVE_PASSWORD = '1_3_give_password';
export const MYTRACK_LOGIN_DIALOG_ACTIVATE_SIM = '1_3_activate_sim';
export const MYTRACK_LOGIN_DIALOG_CALL_HOTLINE = '1_3_call_hotline';

export const MYTRACK_NAV_MY_DATA = '2_1_my_data';
export const MYTRACK_NAV_MY_INBOX = '2_1_open_pobox';
export const MYTRACK_DASHBOARD_SWIPE_TARIFF = '2_1_swipe_tariff';
export const MYTRACK_DASHBOARD_SWIPE_STATUS = '2_1_swipe_status';
export const MYTRACK_DASHBOARD_OVERLAY_UNIT = '2_1_overlay_unit';
export const MYTRACK_DASHBOARD_OVERLAY_DATA = '2_1_overlay_data';
export const MYTRACK_DASHBOARD_OVERLAY_SMS = '2_1_overlay_sms';
export const MYTRACK_DASHBOARD_OVERLAY_MIN = '2_1_overlay_min';
export const MYTRACK_DASHBOARD_CONSUMPTION_COSTS = '2_1_consumption_costs';
export const MYTRACK_DASHBOARD_TOPUP_900 = '2_1_topup_1';
export const MYTRACK_DASHBOARD_TOPUP_1900 = '2_1_topup_2';
export const MYTRACK_DASHBOARD_TOPUP_2900 = '2_1_topup_3';
export const MYTRACK_DASHBOARD_TOPUP_900_CANCEL = '2_1_cancel_topup_1';
export const MYTRACK_DASHBOARD_TOPUP_900_SUBMIT = '2_1_confirm_topup_1';
export const MYTRACK_DASHBOARD_TOPUP_1900_CANCEL = '2_1_cancel_topup_2';
export const MYTRACK_DASHBOARD_TOPUP_1900_SUBMIT = '2_1_confirm_topup_2';
export const MYTRACK_DASHBOARD_TOPUP_2900_CANCEL = '2_1_cancel_topup_3';
export const MYTRACK_DASHBOARD_TOPUP_2900_SUBMIT = '2_1_confirm_topup_3';

export const MYTRACK_DASHBOARD_AUTO_TOPUP = '2_1_activate_comfort_topup';
export const MYTRACK_DASHBOARD_AUTO_TOPUP_DISABLE = '2_1_confirm_and_cancel_comfort_topup';
export const MYTRACK_DASHBOARD_AUTO_TOPUP_ENABLE = '2_1_confirm_comfort_topup';
export const MYTRACK_DASHBOARD_CODE_TOPUP = '2_1_topup_via_code';
export const MYTRACK_DASHBOARD_CODE_TOPUP_SUBMIT = '2_1_confirm_topup_via_code';
export const MYTRACK_DASHBOARD_CODE_TOPUP_CANCEL = '2_1_cancel_topup_via_code';


export const MYTRACK_DASHBOARD_OPEN_POBOX = '2_1_open_pobox';
// export const MYTRACK_DASHBOARD_OPEN_FAQS = '2_1_open_faqs';
export const MYTRACK_DASHBOARD_TARIFF_OPTIONS = '2_1_tariff_options';

export const MYTRACK_CONTRACT_CHANGE_DIALOG = '2_2_overlay_change_tariff';
export const MYTRACK_CONTRACT_CHANGE_DIALOG_CANCEL = '2_2_cancel_change_tariff';
export const MYTRACK_CONTRACT_CHANGE_DIALOG_SUBMIT = '2_2_confirm_change_tariff';
export const MYTRACK_CONTRACT_CHANGE_DIALOG_IMPOSSIBLE = '2_2_change_tariff_impossible';
export const MYTRACK_CONTRACT_CHANGE_DIALOG_SUCCESS = '2_2_change_tariff_success';

export const MYTRACK_CONTRACT_CANCELLATION_OVERVIEW = '2_2_contract_cancellation_tariff_overview';
export const MYTRACK_CONTRACT_CANCELLATION_DETAILS = '2_2_contract_cancellation_tariff_details';
export const MYTRACK_CONTRACT_CANCELLATION_REASON = `2_2_contract_cancellation_with_reason_${TRACK_REPLACE_REASON}`;
export const MYTRACK_CONTRACT_CANCELLATION_BACK = '2_2_contract_cancellation_back';
export const MYTRACK_CONTRACT_CANCELLATION_DIALOG = '2_2_cancel_contract_cancellation_overlay';
export const MYTRACK_CONTRACT_CANCELLATION_DIALOG_CANCEL = '2_2_cancel_contract_cancellation_overlay_cancel';
export const MYTRACK_CONTRACT_CANCELLATION_DIALOG_SUBMIT = '2_2_cancel_contract_cancellation_overlay_confirm';
export const MYTRACK_CONTRACT_CANCEL_CANCELLATION_OVERVIEW = '2_2_cancel_contract_cancellation_tariff_overview';
export const MYTRACK_CONTRACT_CANCEL_CANCELLATION_DETAILS = '2_2_cancel_contract_cancellation_tariff_details';

export const MYTRACK_SERVICE_SIMREP_STEP1 = '2_2_service_simreplacement_step1';
export const MYTRACK_SERVICE_SIMREP_SUMMARY = '2_2_service_simreplacement_summary';
export const MYTRACK_SERVICE_SIMREP_VALUE = `2_2_service_simreplacement_${TRACK_REPLACE_SIMREP_TYPE}`;

export const MYTRACK_SERVICE_SIMACT_SENT = '2_2_service_simactivation_sent';
export const MYTRACK_SERVICE_SIMACT_UNAVAILABLE = '2_2_service_simaction_unavailable';

export const MYTRACK_SERVICE_BMNP_STEP1 = '2_2_service_bmnp_step1';
export const MYTRACK_SERVICE_BMNP_STEP2 = '2_2_service_bmnp_step2';
export const MYTRACK_SERVICE_BMNP_SUMMARY = '2_2_service_bmnp_summary';
export const MYTRACK_SERVICE_BMNP_STATUS = `2_2_service_bmnp_status_${TRACK_REPLACE_BMNP_STATUS}`;
export const MYTRACK_SERVICE_BMNP_TIMING = `2_2_service_bmnp_with_timing_${TRACK_REPLACE_BMNP_TIMING}`;

export const MYTRACK_MYOFFER_PROMOTION_OVERVIEW = `2_2_promotion_details_${TRACK_REPLACE_PROMOTION_ID}`;
export const MYTRACK_MYOFFER_PROMOTION_BOOK_SUBMIT = `2_2_confirm_book_promotion_${TRACK_REPLACE_PROMOTION_ID}`;
export const MYTRACK_MYOFFER_PROMOTION_BOOK_SUCCESS = `2_2_book_promotion_success_${TRACK_REPLACE_PROMOTION_ID}`;

export const MYTRACK_TARIFF_OPTION_BUY = '2_2_buy_tariff_option';

export const MYTRACK_FAQ_TOPIC = `2_2_faqs_overview_${TRACK_REPLACE_TOPIC_ID}`;
export const MYTRACK_FAQ_DETAIL = `2_2_faqs_detail_${TRACK_REPLACE_ANSWER_ID}`;

export const MYTRACK_MYINBOX_VIEW_WEBVIEW = '3_1_view_webview';
export const MYTRACK_MYINBOX_VIEW_BILL = '3_1_view_bill';
export const MYTRACK_MYINBOX_VIEW_EVN = '3_1_view_evn';
export const MYTRACK_MYDATA_DIALOG_PW_CANCEL = '3_1_no_password_typed';
export const MYTRACK_MYDATA_DIALOG_PW_SUBMIT = '3_1_password_typed';

export const MYTRACK_MYDATA_CONTACT_CHANGE = '5_1_change_my_data';
export const MYTRACK_MYDATA_CONTACT_CHANGE_SUBMIT = '5_1_change_my_data_save';
export const MYTRACK_MYDATA_CONTACT_CHANGE_CANCEL = '5_1_change_my_data_back';

export const MYTRACK_MYDATA_PW_CHANGE = '5_1_change_password';
export const MYTRACK_MYDATA_PW_CHANGE_SUBMIT = '5_1_change_password_save';
export const MYTRACK_MYDATA_PW_CHANGE_CANCEL = '5_1_change_password_back';

export const MYTRACK_MYDATA_PAYMENT_CHANGE = '5_1_change_payment_option';
export const MYTRACK_MYDATA_PAYMENT_CHANGE_DEBIT = '5_1_change_payment_option_debit';
export const MYTRACK_MYDATA_PAYMENT_CHANGE_DEBIT_SUBMIT = '5_1_change_payment_option_debit_save';
export const MYTRACK_MYDATA_PAYMENT_CHANGE_DEBIT_CANCEL = '5_1_change_payment_option_debit_back';

export const MYTRACK_MYDATA_EVN_CHANGE = '5_1_change_EVN_option';
export const MYTRACK_MYDATA_EVN_CHANGE_DISABLED = '5_1_change_EVN_option_kein';
export const MYTRACK_MYDATA_EVN_CHANGE_SHORT = '5_1_change_EVN_option_verkuerzt';
export const MYTRACK_MYDATA_EVN_CHANGE_FULL = '5_1_change_EVN_option_ausfuehrlich';
export const MYTRACK_MYDATA_EVN_CHANGE_SUBMIT = '5_1_change_EVN_option_save';
export const MYTRACK_MYDATA_EVN_CHANGE_CANCEL = '5_1_change_EVN_option_back';

export const MYTRACK_MYDATA_ADCONSENT_CHANGE = '5_1_change_beratungseinwilligung';
export const MYTRACK_MYDATA_ADCONSENT_CONTACT_YES = '5_1_change_beratungseinwilligung_kontakt_yes';
export const MYTRACK_MYDATA_ADCONSENT_CONTACT_NO = '5_1_change_beratungseinwilligung_kontakt_no';
export const MYTRACK_MYDATA_ADCONSENT_INFO_YES = '5_1_change_beratungseinwilligung_info_yes';
export const MYTRACK_MYDATA_ADCONSENT_INFO_NO = '5_1_change_beratungseinwilligung_info_no';
export const MYTRACK_MYDATA_ADCONSENT_CHANGE_SUBMIT = '5_1_change_beratungseinwilligung_save';
export const MYTRACK_MYDATA_ADCONSENT_CHANGE_CANCEL = '5_1_change_beratungseinwilligung_back';

export const MYTRACK_MYDATA_BILLING_CHANGE = '5_1_change_rechnungsversand';
export const MYTRACK_MYDATA_BILLING_POST = '5_1_change_rechnungsversand_post';
export const MYTRACK_MYDATA_BILLING_EMAIL = '5_1_change_rechnungsversand_email';
export const MYTRACK_MYDATA_BILLING_SMS = '5_1_change_rechnungsversand_sms';
export const MYTRACK_MYDATA_BILLING_RESEND = '5_1_change_rechnungsversand_resend_email';
export const MYTRACK_MYDATA_BILLING_CHANGE_SUBMIT = '5_1_change_rechnungsversand_save';
export const MYTRACK_MYDATA_BILLING_CHANGE_CANCEL = '5_1_change_rechnungsversand_back';

export const MYTRACK_MYDATA_AVATAR_DIALOG = '5_1_change_avatar_overlay';
export const MYTRACK_MYDATA_AVATAR_DIALOG_SUBMIT = '5_1_change_avatar_overlay_save';
export const MYTRACK_MYDATA_AVATAR_DIALOG_CANCEL = '5_1_change_avatar_overlay_cancel';
export const MYTRACK_MYDATA_AVATAR_CHANGE_IMAGE = `5_1_change_avatar_${TRACK_REPLACE_AVATAR_ID}`;

export const MYTRACK_LOGOUT = '5_1_exit_app';

export const MYTRACK_ERROR_FALLBACK = `0_0_backend_error_${TRACK_REPLACE_ERROR_CODE}`;

export const MYTRACK_NOTIFY_TARIFF_OPTION_CONFIRM = '6_2_confirm_tariff_option';
export const MYTRACK_NOTIFY_TARIFF_OPTION_CANCELATION = '6_2_cancellation_tariff_option';
export const MYTRACK_NOTIFY_TARIFF_OPTION_PAUSED = '6_2_paused_tariff_option';
export const MYTRACK_NOTIFY_LOW_BALANCE = '6_2_balance_too_low';
export const MYTRACK_NOTIFY_PASSWORD_MISSMATCH = '6_2_password_no_matching';
export const MYTRACK_NOTIFY_CODE_TOPUP_SUCCESS = '6_2_topup_code_successful';
export const MYTRACK_NOTIFY_PW_CHANGE_SUCCESS = '6_2_change_password_confirm';
export const MYTRACK_NOTIFY_TOPUP_SUCCESS = '6_2_confirm_direct_topup';

export const MYTRACK_CONTACT_SUBMIT_SUCCESS = '10_1_submit_contact_success';
export const MYTRACK_CONSENT_HANDLING_SUBMIT_SUCCESS = '10_1_submit_consent_handling_success';
export const MYTRACK_EMAIL_REGISTRATION_SUBMIT_SUCCESS = '10_1_submit_email_registration_success';

export const CANCELLATION_REASON_MAPPING = {
  SON: 'sonstige',
  WZA: 'besser',
  TEU: 'teuer',
  SSV: 'service',
  NET: 'netz',
};

/*
=======================================================================================
MARKETING LOCATIONS
=======================================================================================
*/

export const MARKETING_LOCATION_TARIFF_DETAILS = 'tariffDetails';
export const MARKETING_LOCATION_TARIFF_LIST_BOXED = 'tariffListBoxed';
export const MARKETING_LOCATION_CHECKOUT_CART = 'checkoutCart';
export const MARKETING_LOCATION_CHECKOUT_CART_LAYER = 'checkoutCartLayer';
export const MARKETING_LOCATION_VVL_CHECKOUT_CONFIRM = 'vvlCheckoutConfirm';

/*
=======================================================================================
CONTRACT ACTIONS
=======================================================================================
*/

export const CONTRACT_RENEWAL_WITH_HARDWARE = 'contractRenewalWithHardware';
export const CONTRACT_RENEWAL_WITHOUT_HARDWARE = 'contractRenewalWithoutHardware';
export const CONTRACT_TARIFF_CHANGE = 'tariffChange';

/*
=======================================================================================
FORM TYPES
=======================================================================================
*/

export const CONTACT_FORM_TYPE_GDPR = 'gdpr';
export const CONTACT_FORM_TYPE_DEFAULT = 'default';

/*
=======================================================================================
SCOPES see https://confluence.db-n.com/pages/viewpage.action?pageId=20778907
=======================================================================================
 */

export const SCOPE_MYDATA_WRITE_AVATAR = 'mydata:write:avatar';
export const SCOPE_MYDATA_WRITE_CONTACT_EMAIL = 'mydata:write:contact:email';
export const SCOPE_MYDATA_WRITE_CONTACT_NUMBER = 'mydata:write:contact:number';
export const SCOPE_MYDATA_WRITE_CONTACT_ADDRESS = 'mydata:write:contact:address';
export const SCOPE_MYDATA_READ_PAYMENTTYPE = 'mydata:read:paymentType';
export const SCOPE_MYDATA_READ_BILLDELIVERY = 'mydata:read:billDelivery';
export const SCOPE_MYDATA_WRITE_PAYMENTTYPE = 'mydata:write:paymentType';
export const SCOPE_MYDATA_WRITE_BILLDELIVERY = 'mydata:write:billDelivery';
export const SCOPE_MYDATA_WRITE_EVN = 'mydata:write:evn';
export const SCOPE_MYDATA_READ_EVN = 'mydata:read:evn';
export const SCOPE_MYDATA = 'mydata';
export const SCOPE_MYDATA_WRITE = 'mydata:write';
export const SCOPE_MYDATA_WRITE_CONSENTS = 'mydata:write:consents';
export const SCOPE_MYDATA_WRITE_GDPR = 'mydata:write:gdpr';
export const SCOPE_MYDATA_READ = 'mydata:read';
export const SCOPE_MYDATA_READ_CONSENTS = 'mydata:read:consents';
export const SCOPE_MYDATA_READ_GDPR = 'mydata:read:gdpr';
export const SCOPE_MYDATA_WRITE_SERVICE_OPTIONS = 'mydata:write:serviceoptions';
export const SCOPE_MYTERMINATION = 'myterminated';
export const SCOPE_MYDASHBOARD = 'mydashboard';
export const SCOPE_NOTIFICATIONS = 'mynotifications';
export const SCOPE_MYSIMCARD_PASSIVATION = 'mysimcardpassivation';
export const SCOPE_MYSIMCARD_REPLACEMENT = 'mysimcardreplacement';
export const SCOPE_MYSIMCARD_ESIM = 'mysimcard:esim';
export const SCOPE_MYFRIEND = 'myfrd';
export const SCOPE_MYFRIEND_READ = 'myfrd:read';

/*
=======================================================================================
CONTRACT STATUS
=======================================================================================
 */
export const CONTRACT_TERMINATED = 'TER';
export const CONTRACT_DISMISSED = 'DIS';
export const CONTRACT_CANCELED = 'CAN';

/*
=======================================================================================
Path to dynamic loaded Assets
=======================================================================================
 */
export const ICON_WHATSAPP_SHARE_BUTTON = '/files/2019-09/whatsapp_logo1.svg';
export const ICON_WHATSAPP_SHARE_BUTTON_PNG = '/files/2019-10/whatsapp.png';
export const IMAGE_SIM_CARD_PNG = '/files/default_images/sim_karte.png';

/*
=======================================================================================
DIALOG IDS
=======================================================================================
 */
export const DIALOG_ID_MNP_OPTIN = 'de-mnpout-optin';
export const DIALOG_ID_CONTRACT_SUMMARY_PUC = 'de-cs-on-demand-puc';
export const DIALOG_ID_CONTRACT_SUMMARY_PUA = 'de-cs-on-demand-pua';
export const DIALOG_ID_FWF_DASHBOARD_LEGAL = 'de-frd-legal';
export const DIALOG_ID_FWF_DASHBOARD_INFO = 'de-frd-info';
export const DIALOG_ID_SHOPPING_CART_ESIM_HINT = 'de-shopping-cart-esim-hint';
export const DIALOG_ID_ESIM_SETTINGS = 'de-esim-menu-settings';
export const DIALOG_ID_SHIPPING_INFO = 'de-shipping-info';
export const DIALOG_ID_SHIPPING_FREE_INFO = 'de-shipping-free-info';

