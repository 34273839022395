import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MENU_IDENTIFIER_MAIN, MENU_IDENTIFIER_FOOTER, MENU_IDENTIFIER_META } from '../../helpers/constants';
import UniqueIdProvider from '../../components/basics/service/UniqueIdProvider';
import ServiceViewportProvider from '../../components/basics/service/ServiceViewportProvider';
import ServiceSiteProvider from '../../components/basics/service/ServiceSiteProvider';
import ServiceAklamio from '../../components/basics/service/ServiceAklamio';
import ServiceEqualizer from '../../components/basics/service/ServiceEqualizer';
import ServiceToolTip from '../../components/basics/service/ServiceToolTip';
import ServiceScreenInputDetection from '../../components/basics/service/ServiceScreenInputDetection';
import UIProvider from '../../components/basics/ui/UIProvider';
import Tracking from '../tracking/Tracking';
import TrackingClickDelegation from '../tracking/TrackingClickDelegation';
import GlobalMetadata from './GlobalMetadata';
import GlobalProgressBar from './GlobalProgressBar';
import GlobalHeader from './GlobalHeader';
import GlobalFooter from './GlobalFooter';
import GlobalDialogs from './GlobalDialogs';
import GlobalCustomerService from './GlobalCustomerService';
import GlobalLoadingSpinner from './GlobalLoadingSpinner';
import GlobalNotifications from './GlobalNotifications';
import GlobalVVLBar from './GlobalVVLBar';
import GlobalInfoBar from './GlobalInfoBar';
import SmartBanner from '../smartbanner/smartbanner';
import ServiceLottie from '../../components/basics/service/ServiceLottie';
import RenderOnClient from '../../components/basics/ssr/RenderOnClient';

class GlobalLayout extends PureComponent {

  render() {
    const {
      children,
      isAppView,
      isInboxView,
      brandName,
      pplus,
      isBrowserReadyToRoute,
      location,
      isCookieConsent,
      isAppDarkMode,
    } = this.props;
    const className = brandName ? `branded brand-${brandName}` : '';
    const colorMode = isAppDarkMode ? 'dark' : 'light';
    return (
      <ServiceViewportProvider>
        <UniqueIdProvider>
          <UIProvider>
            <ServiceSiteProvider>
              <ServiceEqualizer>
                <TrackingClickDelegation>
                  <ServiceToolTip delay={250} />
                  <ServiceScreenInputDetection />
                  <Tracking />
                  <div className={className} data-color-mode={colorMode}>
                    {!isAppView && !isCookieConsent && <SmartBanner />}
                    <RenderOnClient>
                      <GlobalProgressBar />
                    </RenderOnClient>
                    <GlobalMetadata location={location} />
                    {!isAppView && !isInboxView && !pplus &&
                      <GlobalHeader identifierNavigation={MENU_IDENTIFIER_MAIN} fixed />
                    }
                    <GlobalInfoBar />
                    {(!process.browser || isBrowserReadyToRoute) && children}
                    {!isAppView && !isInboxView && !pplus &&
                      <GlobalFooter
                        identifierMeta={MENU_IDENTIFIER_META}
                        identifierFooter={MENU_IDENTIFIER_FOOTER}
                      />
                    }
                    {!isAppView && !isInboxView && <GlobalVVLBar />}
                    {!isAppView &&
                      <RenderOnClient>
                        <GlobalCustomerService />
                      </RenderOnClient>
                    }
                    <GlobalNotifications />
                    <GlobalDialogs />
                    <GlobalLoadingSpinner />
                  </div>
                  <ServiceAklamio />
                  <ServiceLottie />
                </TrackingClickDelegation>
              </ServiceEqualizer>
            </ServiceSiteProvider>
          </UIProvider>
        </UniqueIdProvider>
      </ServiceViewportProvider>
    );
  }
}

GlobalLayout.propTypes = {
  children: PropTypes.node,
  isInboxView: PropTypes.bool.isRequired,
  isAppView: PropTypes.bool.isRequired,
  brandName: PropTypes.string,
  pplus: PropTypes.bool,
  isBrowserReadyToRoute: PropTypes.bool,
  location: PropTypes.object.isRequired,
  isCookieConsent: PropTypes.bool,
  isAppDarkMode: PropTypes.bool,
};

const mapStateToProps = ({ site, user, routing }) => ({
  isInboxView: site.isInboxView,
  isAppView: Boolean(site.appView),
  brandName: site.brandName,
  pplus: !!user.pplus,
  requestedLocation: site.routing.requestedLocation,
  location: routing.locationBeforeTransitions,
  isBrowserReadyToRoute: site.isBrowserReadyToRoute,
  isCookieConsent: site.dialog && site.dialog.theme === 'cookieconsent',
  isAppDarkMode: site.isAppDarkMode,
});

export default connect(mapStateToProps)(GlobalLayout);
