import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Unit from '../../components/basics/text/TextUnit';
import { getHardwarePricePrefix, getTariffHardwareRelation } from '../../helpers/entity';
import { propTypes as UnitProptypes } from '../../propTypes/unit';
import { shape as hardwareShape } from '../../propTypes/hardware';
import connectUI from '../../components/basics/ui/UIConnector';
import {
  AVAILABILITY_ACCOUNT,
  AVAILABILITY_PRESALES,
  PAYMENT_FEE_MONTHLY,
  PAYMENT_FEE_SINGLE,
} from '../../helpers/constants';
import { isAccountUrl } from '../../config/api';
/**
 * Renders a price which is calculated on the basis
 * of the provided hardware and tariff.
 */
export function HardwarePaymentUnitComposition(props) {
  const { hardware, tariffId, paymentType } = props;
  // TODO: remove tariffHardwareRelation when all input for Unit is normalized, fallback for now
  const tariffRelation = getTariffHardwareRelation(hardware, tariffId);
  const hardwarePrice = hardware[paymentType] || tariffRelation[paymentType];
  const prefixPrice = hardware[`${paymentType}Strike`];
  const prefix = getHardwarePricePrefix(hardwarePrice, prefixPrice, props);
  return (
    <Unit
      price={hardwarePrice}
      prefixPrice={prefixPrice}
      prefix={prefix}
      {...props}
    />
  );
}

// slice property price from proptypes as the price is calculated by our component
// eslint-disable-next-line no-unused-vars
const { price, ...unitProptypes } = UnitProptypes;

HardwarePaymentUnitComposition.propTypes = {
  ...unitProptypes,
  tariffId: PropTypes.string,
  hardware: hardwareShape.isRequired,
  paymentType: PropTypes.oneOf([
    PAYMENT_FEE_MONTHLY,
    PAYMENT_FEE_SINGLE,
  ]).isRequired,
  prefix: PropTypes.string,
  currentRealm: PropTypes.string,
  ui: PropTypes.shape({
    guiWordStartingFrom: PropTypes.string.isRequired,
  }),
};


/* QUICK & DIRTY FIX FOR:
  * https://jira.db-n.com/browse/OP-1370
  * currentRealm is needed for determining if we should apply
  * the fix
*/
function mapStateToProps(state) {
  const { site, routing } = state;
  const isContractRenewal = site.contractRenewal.isInProgress;
  const currentRealm = (isAccountUrl(routing.locationBeforeTransitions) || isContractRenewal)
    ? AVAILABILITY_ACCOUNT
    : AVAILABILITY_PRESALES;
  return {
    currentRealm,
  };
}

export default compose(
  connectUI(),
  connect(mapStateToProps),
)(HardwarePaymentUnitComposition);
