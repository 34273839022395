import { ACTION_PREFIX } from '../../helpers/constants';

export const REGISTER_VOID = `${ACTION_PREFIX}/REGISTER_VOID`;
export const REGISTER_CID = `${ACTION_PREFIX}/REGISTER_CID`;
export const REGISTER_AFFILIATE_ID = `${ACTION_PREFIX}/REGISTER_AFFILIATE_ID`;
export const REGISTER_PROMOTION_CODES = `${ACTION_PREFIX}/REGISTER_PROMOTION_CODES`;
export const REMOVE_PROMOTION_CODES = `${ACTION_PREFIX}/REMOVE_PROMOTION_CODES`;
export const REGISTER_CAMPAIGN_TOKEN = `${ACTION_PREFIX}/REGISTER_CAMPAIGN_TOKEN`;
export const REMOVE_CAMPAIGN_TOKEN = `${ACTION_PREFIX}/REMOVE_CAMPAIGN_TOKEN`;
export const RECEIVE_AFFILIATE_PARTNER_LINK = `${ACTION_PREFIX}/RECEIVE_AFFILIATE_PARTNER_LINK`;
export const RECEIVE_PPLUS_DATA = `${ACTION_PREFIX}/RECEIVE_PPLUS_DATA`;
export const REGISTER_ORDER_BY_AGENT = `${ACTION_PREFIX}/REGISTER_ORDER_BY_AGENT`;
export const REGISTER_DENIED_PROMOTIONS = `${ACTION_PREFIX}/REGISTER_DENIED_PROMOTIONS`;
export const REGISTER_GRANTED_PROMOTIONS = `${ACTION_PREFIX}/REGISTER_GRANTED_PROMOTIONS`;
export const REGISTER_GRANTED_CAMPAIGN_PROMOTIONS = `${ACTION_PREFIX}/REGISTER_GRANTED_CAMPAIGN_PROMOTIONS`;

export const registerVoId = (bId, middleware = false) => ({
  type: REGISTER_VOID,
  meta: { middleware },
  payload: { bId },
});

export const registerCId = cId => ({
  type: REGISTER_CID,
  payload: { cId }, // @todo: trim string
});

export const registerAffiliateId = affId => ({
  type: REGISTER_AFFILIATE_ID,
  payload: { affId },
});

export const registerPromoCodes = (promoCodes, middleware = false) => (dispatch) => {
  dispatch({
    type: REGISTER_PROMOTION_CODES,
    meta: { middleware },
    payload: { promoCodes },
  });
};

export const removePromoCodes = () => ({
  type: REMOVE_PROMOTION_CODES,
  payload: null,
});

export const registerCampaignToken = (campaignToken) => (dispatch) => {
  dispatch({
    type: REGISTER_CAMPAIGN_TOKEN,
    payload: { campaignToken },
  });
};

export const removeCampaignToken = () => ({
  type: REMOVE_CAMPAIGN_TOKEN,
  payload: null,
});

export const registerGrantedPromotions = (grantedPromotions) => ({
  type: REGISTER_GRANTED_PROMOTIONS,
  payload: { grantedPromotions },
});

export const registerGrantedCampaignPromotions = (grantedCampaignPromotions) => ({
  type: REGISTER_GRANTED_CAMPAIGN_PROMOTIONS,
  payload: { grantedCampaignPromotions },
});

/**
 * @param affPartner {bool} Tells if the customer is landing via an affiliate link
 */
export const receiveAffiliatePartnerLink = (affPartner) => ({
  type: RECEIVE_AFFILIATE_PARTNER_LINK,
  payload: { affPartner },
});

/**
 * Set the site into pplus mode.
 * @param msisdn The msisdn of the pplus user.
 */
export const receivePPlusData = (msisdn) => ({
  type: RECEIVE_PPLUS_DATA,
  payload: {
    pplus: {
      msisdn,
    },
  },
});

export const orderByAgent = (bool = true) => ({
  type: REGISTER_ORDER_BY_AGENT,
  payload: {
    orderByAgent: bool,
  },
});

/**
 * Store invalid promo code in denied promotions
 * @param promoCodes
 * @returns {(function(*): void)|*}
 */

export const registerDeniedPromotions = (promoCodes) => (dispatch) => {
  dispatch({
    type: REGISTER_DENIED_PROMOTIONS,
    payload: { promoCodes },
  });
};
