import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import initForm from '../../form/FormInitializer';
import MyBelatedMnpFormContract from '../../../components/compositions/account/belatedMnp/MyBelatedMnpFormContract';
import { createFieldMapBelatedMnpStepContract } from '../../form/steps/formStepMnp';
import { trackMyState } from '../../../actions/tracking/event';
import {
  FORM_ID_BMNP_CONTRACT,
  FORM_ID_BMNP_CONFIRM,
  MYTRACK_SERVICE_BMNP_STEP2,
  MARKET_POSTPAID,
  E_SIM,
} from '../../../helpers/constants';

const mapStateToProps = (state, { ui, fieldMap, form }) => {
  const { user } = state;
  const selector = formValueSelector(form);
  const activeTab = selector(state, fieldMap.mnpPortingDateType.name);
  const activeMailOption = selector(state, fieldMap.mnpMailOption.name);
  const isPuc = state.user.market === MARKET_POSTPAID;
  const isContractSimTypeEsim = state.user.contractData
    && state.user.contractData.simCard.simType === E_SIM;
  const optionsIndex = isContractSimTypeEsim ? 1 : 0;

  // @todo is this intentional? – we select field values from where? that were set when?
  const initialValues = {
    [fieldMap.mnp.name]: true,
    [fieldMap.mnpPortingDateType.name]: fieldMap.mnpPortingDateType.options[0].value,
    [fieldMap.mnpSimType.name]: fieldMap.mnpSimType.options[optionsIndex].value,
    [fieldMap.mnpSalutation.name]: selector(state, 'salutation'),
    [fieldMap.mnpFirstName.name]: selector(state, 'first_name'),
    [fieldMap.mnpLastName.name]: selector(state, 'last_name'),
    [fieldMap.mnpBirthday.name]: selector(state, 'birthday'),
    [fieldMap.mnpMailOption.name]: fieldMap.mnpMailOption.options[0].value,
    [fieldMap.mnpMailInput.name]: selector(state, 'mail_input'),
  };

  return {
    activeTab,
    activeMailOption,
    mnpCancelationCopy: isPuc ? ui.txtMnpHelpCopyPuc : ui.txtMnpHelpCopy,
    mnpCurrentDataHeadline: ui.mnpFormDataTitle,
    mnpConfirmDataCopy: ui.txtMnpHintCopy,
    mnpBeforeEndCopy: isPuc ? ui.txtMnpFormBeforeCopyPuc : ui.txtMnpFormBeforeCopy,
    mnpWithEndCopy: isPuc ? ui.txtMnpFormEndCopyPuc : ui.txtMnpFormEndCopy,
    mnpHeadline: ui.mnpFormTitle,
    initialValues,
    isContractSimTypeEsim,
    user,
  };
};

const component = compose(
  connect(mapStateToProps),
  initForm(),
)(MyBelatedMnpFormContract);

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id: FORM_ID_BMNP_CONTRACT,
  label: props.ui.cfoNavMnp,
  component,
  fieldMap: createFieldMapBelatedMnpStepContract(state, { ...props, isMyBelatedMnpForm: true }),
  onStepEnter: () => (dispatch) => dispatch(trackMyState(MYTRACK_SERVICE_BMNP_STEP2)),
  next: FORM_ID_BMNP_CONFIRM,
});

export default mapStateToFormConfig;
