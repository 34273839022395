import { v4 as uuidV4 } from 'uuid';

import { isAccountEndpoint } from '../../config/api';
import ApiError from '../errors/ApiError';
import {
  HEADER_DEFAULT_MIME_WEB,
  HEADER_KEY_TRANSACTION_ID,
  REQUEST_METHOD_GET,
  REQUEST_METHOD_POST, STORAGE_KEY_SESSION_ID,
} from '../../helpers/constants';
import { getUrlPath } from '../../helpers/url';
import LogContext from '../logging/LogContext';
import { decamelizeObjectKeys } from '../../helpers/str';
import { getItem, STORAGE_TYPE_SESSION_STORAGE } from '../../helpers/storage';
import { isBridgeEnabled } from '../../helpers/site';
import { isIE11 } from '../../helpers/misc';

/**
 * Creates a shortened unique id consting of alphanumeric characters
 * to be used as transaction id.
 *
 * @returns {string} id
 */
export const getShortenedUuid = prefix => `${prefix}${uuidV4().split('-')[0]}`;

export const REQUEST_ID_PREFIX = '@@request';

/**
 * QueueableRequest are requests that are executed one after another.
 */
class QueueableRequest {
  /**
   * @param {string} url
   * @param {Object} [options]
   */
  constructor(url, options) {
    options = options || {}; // eslint-disable-line no-param-reassign
    this.url = url;
    this.method = options.method ? options.method.toUpperCase() : REQUEST_METHOD_GET;
    this.transactionId = getShortenedUuid('FE_');
    this.context = options.context || new LogContext();
    this.headers = {
      ...(options.headers || {}),
      [HEADER_KEY_TRANSACTION_ID]: this.transactionId,
    };
    /**
     * fixes https://jira.db-n.com/browse/OT-7436
     */
    if (this.method === REQUEST_METHOD_GET && isIE11()) {
      this.headers.Pragma = 'no-cache';
    }
    this.payload = options.payload;
    this.priority = options.priority == null ? QueueableRequest.PRIO_50 : options.priority;
    this.id = this.getId(this.method, this.url);
    this.preventDefaultErrorHandling = options.preventDefaultErrorHandling || false;
    this.isBlocking = options.isBlocking != null // not null or undefined
      ? options.isBlocking
      : this.method !== REQUEST_METHOD_GET;

    // whether or not to decamelize the request body before sending it to the server
    this.isDecamelizePayload = options.isDecamelizePayload != null // not null or undefined
      ? !!options.isDecamelizePayload
      : true;
    // whether or not to camelize the response body
    this.camelizeResponse = (typeof options.camelizeResponse !== 'undefined')
      ? options.camelizeResponse
      : options.responseDataType !== QueueableRequest.RESPONSE_DATA_TYPE_TEXT;
    /**
     * whether or not the system should go into maintainance if this request fails.
     * @type {boolean}
     */
    this._isCritical = !!options.isCritical;

    this.responseDataType = options.responseDataType || QueueableRequest.RESPONSE_DATA_TYPE_JSON;
    this.tries = 0;
    this.maxTries = 8;
    // early bindind so it is easier to used in array methods (e.g. filter, sort)
    this.compare = this.compare.bind(this);
    this.isEqual = this.isEqual.bind(this);
  }

  getSerializedPayload() {
    return this.payload && (
      this.isDecamelizePayload
        ? JSON.stringify(decamelizeObjectKeys(this.payload))
        : JSON.stringify(this.payload)
    );
  }

  /**
   * Returns an id that identifies this request.
   *
   * Note: Once the id has been generated, it cannot be changed anymore.
   */
  getId(method, url) {
    if (!this.id) {
      // we only use the pathname (excluding the domain etc.) to ensure that
      // requests created during server-side rendering have the same id as
      // requests created on the client side.
      const path = getUrlPath(url);
      let id = `${REQUEST_ID_PREFIX}_${method}_${path}`;
      if (method.toUpperCase() !== REQUEST_METHOD_GET) {
        // add a unique random value to non reading requests
        id += this.transactionId;
      }
      this.id = id;
    }

    return this.id;
  }

  /**
   * Returns true if this request is more (or equally) important compared to
   * the request passed as argument.
   *
   * @param {QueueableRequest} request
   * @return {boolean}
   */
  compare(request) {
    return this.priority >= request.priority;
  }

  /**
   * Whether or not the screen should be blocked while
   * this request is executed.
   *
   * @param {bool} isBlocking
   */
  setBlocking(isBlocking) {
    this.isBlocking = isBlocking;
  }

  toString() {
    return this.id;
  }

  /**
   * Two requests are equal if they are instances of the same class.
   *
   * @param {QueueableRequest} request
   */
  isEqual(request) {
    return this.id === request.id;
  }

  /**
   * Checks whether the request is account-related and therefore requires
   * sequential loading.
   */
  isSecure() {
    return isAccountEndpoint(this.url);
  }

  /**
   * If the request fails and this method returns false, the error won't be
   * shown in the global notification bar, as it would be the case otherwise.
   *
   * Note: Override this method to change the behavior for a specific request.
   *
   * @param {ApiError} error
   */
  isPreventDefaultErrorHandling(error) {
    return (
      this.preventDefaultErrorHandling ||
      (error instanceof ApiError && [422, 503, 401].includes(error.fullResponse.status))
    );
  }

  /**
   * Actively prevents the default error handling if set to true.
   *
   * @param state
   */
  setPreventDefaultErrorHandling(state) {
    this.preventDefaultErrorHandling = !!state;
  }

  /**
   * Returns a list of requests to be fired in case this request has been
   * successful.
   *
   * Note this method will only be called for non-GET requests.
   *
   * The idea is that every mutating request contains a list of follow-up
   * requests that re-fetch the updated data
   *
   * @param {object} state - the current state
   * @param {ApiResponse} response
   * @return {QueueableRequest[]}
   */
  getSubsequentRequests(state, response) { // eslint-disable-line no-unused-vars
    return [];
  }

  isGET() {
    return this.method === REQUEST_METHOD_GET;
  }

  isPOST() {
    return this.method === REQUEST_METHOD_POST;
  }

  isCritical() {
    return this._isCritical;
  }

  setCritical(isCritial) {
    this._isCritical = isCritial;
  }

  /**
   * @param {LogContext} context
   */
  setContext(context) {
    this.context = context;
  }

  /**
   * The context will be picked up by our logging system.
   * @return {LogContext}
   */
  getContext() {
    return this.context;
  }

  /**
   * Once this request has been executed successfully, this function is called.
   * It allows us to parse the response and trigger some actions in turn.
   * Moreover, it allows us to transform (normalize) the original response body.
   *
   * @param {ApiResponse} response
   * @param {function} dispatch
   * @param {function} getState
   *
   * @return {ApiResponse} The updated or unchanged ApiResponse
   */
  handleResponse(response, dispatch, getState) { // eslint-disable-line no-unused-vars
    return response;
  }

  sendCredentials() {
    return false;
  }

  /**
   * This method may be overwritten by its inheriting classes and returns a response, which is
   * used for mocking purposes. Use createFakeResponse helper to create a Response instance.
   * @return {Response|Promise<Response>}
   */
  getFakeResponse() {
    return null;
  }

  getHeaders(state) {
    const { user, site } = state;
    const headers = {
      ...this.headers,
      'Content-Type': 'application/json',
      'Accept': HEADER_DEFAULT_MIME_WEB, // eslint-disable-line quote-props
    };

    headers['X-Session-Id'] = getItem(STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_SESSION_ID);
    if (this.isSecure() && user.credentials.nonce) {
      // in order to not confuse the MVC it's better not sending the
      // JWT and nonce for non-secure requests
      headers['X-Nonce'] = user.credentials.nonce;
      headers['X-Identifier'] = isBridgeEnabled(site) ? 'otelo-app' : 'otelo';
    }

    return headers;
  }

  /**
   * Stops the request from being triggered
   * @return {boolean}
   */
  block(state) { // eslint-disable-line no-unused-vars
    return false;
  }

  increaseTries() {
    this.tries++;
  }
}

QueueableRequest.PRIO_0 = 0;
QueueableRequest.PRIO_10 = 10;
QueueableRequest.PRIO_20 = 20;
QueueableRequest.PRIO_30 = 30;
QueueableRequest.PRIO_40 = 40;
QueueableRequest.PRIO_50 = 50;
QueueableRequest.PRIO_60 = 60;
QueueableRequest.PRIO_70 = 70;
QueueableRequest.PRIO_80 = 80;
QueueableRequest.PRIO_90 = 90;
QueueableRequest.PRIO_100 = 100;

QueueableRequest.RESPONSE_DATA_TYPE_JSON = 'json';
QueueableRequest.RESPONSE_DATA_TYPE_TEXT = 'text';
QueueableRequest.RESPONSE_DATA_TYPE_BINARY = 'blob';

export default QueueableRequest;
