import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { extractDataAttributes } from '../../../helpers/misc';

function FormOptionBullet(props) {
  const inputProps = {
    id: props.id,
    name: props.name,
    value: props.value,
    checked: props.checked,
  };

  const states = [
    props.checked && 'selected',
    props.disabled && ' disabled',
  ];

  const modifiers = [
    props.filled && 'filled',
  ];

  const dataset = extractDataAttributes(props.data);

  return (
    <div
      className={suitcss({
        componentName: 'FormOptionBullet',
        states,
        modifiers,
      })}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      {...dataset}
    >
      <input
        className={suitcss({
          componentName: 'FormOptionBullet',
          descendantName: 'input',
        })}
        type="radio"
        {...inputProps}
        onChange={props.onChange}
        data-tracking="off"
      />

      <label
        className={suitcss({
          componentName: 'FormOptionBullet',
          descendantName: 'label',
        })}
        htmlFor={props.id}
      >
        <span
          className={suitcss({
            componentName: 'FormOptionBullet',
            descendantName: 'labelInner',
          })}
          style={{ backgroundColor: props.value }}
        />
      </label>
    </div>
  );
}

FormOptionBullet.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  data: PropTypes.object,
};

FormOptionBullet.defaultProps = {
  checked: false,
  disabled: false,
  filled: false,
};

export default FormOptionBullet;
