import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import { navLinkList } from '../../../propTypes/navigation';

import SitemapLinkNavigation from './SitemapLinkNavigation';
import SitemapAccordionNavigation from './SitemapAccordionNavigation';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';

class Sitemap extends PureComponent {
  render() {
    const { items, isMediaS } = this.props;
    const sections = items.filter(item => item.class !== 'nav-footer-sponsor');
    const sponsors = items.filter(item => item.class === 'nav-footer-sponsor');
    /**
     * SEO: changed parent from LazyShow to div
     * @see https://jira.db-n.com/browse/OP-2177
     */
    return (
      <div
        className={suitcss({
          componentName: 'Sitemap',
        })}
      >
        <div
          className={suitcss({
            componentName: 'Sitemap',
            descendantName: 'sections',
          })}
        >
          { isMediaS ? <SitemapAccordionNavigation items={sections} /> :
            <SitemapLinkNavigation items={sections} />}
        </div>
        {!!sponsors.length && (
          <div
            className={suitcss({
              componentName: 'Sitemap',
              descendantName: 'sponsors',
            })}
          >
            <nav
              className={suitcss({
                componentName: 'Sitemap',
                descendantName: 'logos',
              })}
            >
              {sponsors.map((item, index) => (
                <div
                  className={suitcss({
                    componentName: 'Sitemap',
                    descendantName: 'logo',
                  })}
                  key={index}
                >
                  {item.url &&
                    <a
                      href={item.url}
                      title={item.title}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <img src={item.icon} alt={item.title} />
                    </a>
                  }
                  {!item.url &&
                    <img src={item.icon} alt={item.title} />
                  }
                </div>
              ))}
            </nav>
          </div>
        )}
      </div>
    );
  }
}

Sitemap.propTypes = {
  items: navLinkList.isRequired,
  isMediaS: PropTypes.bool,
};

export default matchMediaConnector(['isMediaS'])(Sitemap);
